import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography } from '@material-ui/core';

import { ChromePicker } from 'react-color';

export default function ProductColorPicker({ showPreview, backgroundColor, setBackgroundColor, textColor, setTextColor, newImages, imagesArray=null }) {

    const { t } = useTranslation();
    const [codePreviewImage, setCodePreviewImage] = useState([]);

    useEffect(() => {
        if(newImages?.[0]){
            const objectUrl = URL.createObjectURL(newImages?.[0]);
            setCodePreviewImage(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }else{
            setCodePreviewImage(null);
        }
     }, [newImages])

    return (
        <div>
            {showPreview && (
                <Grid container spacing={2} >
                    {/* Image Preview */}
                    <Grid item xs={12} sm={12} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            maxWidth: '420px',
                            height: '180px',
                            marginBottom: '100px',
                            backgroundColor: backgroundColor,
                            borderRadius: '15px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}>
                            <div style={{
                                position: 'absolute',
                                top: '15%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: textColor,
                                fontWeight: 'bold',
                                fontSize: '20px',
                                zIndex: 1,
                                textAlign: 'center'
                            }}>
                                {t('title.title')}
                            </div>
                            <img
                                src={_.size(imagesArray) > 0 ? imagesArray?.[0] : (codePreviewImage ? codePreviewImage : "/images/empty_state/no-image.png")}
                                alt="no-preview"
                                style={{
                                    position: 'absolute',
                                    top: '100%',
                                    left: '50%',
                                    maxWidth: 230,
                                    width: '80%',
                                    height: 'auto',
                                    transform: 'translate(-50%, -50%)',
                                    borderRadius: '15px',
                                    zIndex: 1,
                                    aspectRatio: '1/1',
                                    objectFit: 'cover',
                                }}
                            />
                        </div>
                    </Grid>
                    {/* Background Color Picker */}
                    <Grid item xs={12} sm={6} lg={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                            {t('product.colorPicker.backgroundColor')}
                        </Typography>
                        <ChromePicker
                            color={backgroundColor}
                            onChangeComplete={(color) => setBackgroundColor(color.hex)}
                        />
                    </Grid>
                    {/* Text Color Picker */}
                    <Grid item xs={12} sm={6} lg={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                            {t('product.colorPicker.textColor')}
                        </Typography>
                        <ChromePicker
                            color={textColor}
                            onChangeComplete={(color) => setTextColor(color.hex)}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    );
}