import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
// import { useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
// import { useQuery } from '@utils/Tools';

import _ from 'lodash';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Typography, Link, Grid } from '@material-ui/core';
import { useTheme, TextField,Button, Dialog, DialogTitle, DialogContent, Box, MenuItem, DialogActions, Tab, Tabs } from '@material-ui/core';

import ReorderIcon from '@material-ui/icons/Reorder';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';


export default function BicpayWithdrawal() {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [inputErrors, setInputErrors] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const { permissions } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletActionDialog, setWalletActionDialog] = useState({
        open: false,
        id: 0,
        type: '',
        remark: '',
    });
    const [statusList, setStatusList] = useState([]);
    const [originalAmount, setOriginalAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [value, setValue] = useState('pending');
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const callApi = () => {
        setOriginalAmount(0);
        setFinalAmount(0);
        isMountedRef.current = true;
        setTableLoading(true);
        let apiData = {
            'status': value
        };
        getUrl(`admin/wallet-action`, apiData).then(response => {
            setTableLoading(false);
            if (response.status === 1 && isMountedRef.current) {
                setData(response.data);
                setStatusList(response.status_list);
                setOriginalAmount(response.sum_original_amount);
                setFinalAmount(response.sum_final_amount);
            }
        }).catch((error) => {
            setTableLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
    }
    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [ value ]);

    const getStatusDescription = (statusList, statusValue) => {
        const statusItem = statusList.find(item => item.value == statusValue);
        
        if (statusItem) {
            const [enDesc, cnDesc] = statusItem.desc.split('|');
            return i18n.language === 'zh' ? cnDesc : enDesc;
        }
        return '';
    };

    const handleWithdrawalDialogClose = () => {
        setInputErrors({});
        setWalletActionDialog({
            open: false,
            id: 0,
            type: '',
            remark: '',
        });
    }

    const handleOpenDialogWithSelected = () => {
        if (selectedRows.length > 0) {
            setWalletActionDialog({
                open: true,
                id: selectedRows[0].id,
                type: '',
                remark: '',
            });
        }
    };    

    const updateWithdrawalStatus = () => {
        setLoading(true);
        setWalletActionDialog({ ...walletActionDialog, open: false });
        const ids = selectedRows.map(row => row.id);
        const type = walletActionDialog.type;
        const remark = walletActionDialog.remark;
    
        let updateData = {
            ids: ids,
            type: type,
            remark: remark
        };
        postUrl(`admin/wallets/withdrawal`, updateData).then(response => {
            setLoading(false);
            if (response.status) {
                handleWithdrawalDialogClose();
                addAlert('', t('success.editSuccess'), 'success', '');
                callApi();
            } else {
                setInputErrors(response.errors);
                setWalletActionDialog({ ...walletActionDialog, open: true });
                addAlert('', response.message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            setWalletActionDialog({ ...walletActionDialog, open: true });
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };

    const updateStatusDialog = () => {
        return (
            <Dialog open={walletActionDialog.open} onClose={() => handleWithdrawalDialogClose()} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('reports.reportWalletAction.updateStatusTitle')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                                <TextField
                                    label={t(`reports.reportWalletAction.status`)}
                                    variant="outlined"
                                    value={walletActionDialog?.type}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                                    error={inputErrors && inputErrors.type ? true : false}
                                    name="type"
                                    select
                                    onChange={({ target }) => setWalletActionDialog({ ...walletActionDialog, type: target.value })}
                                    style={{ width: '100%'}}
                                >
                                    <MenuItem value='' disabled>
                                        {t('general.pleaseSelect')}
                                    </MenuItem>
                                    <MenuItem value="withdrawal">
                                        {t(`reports.reportWalletAction.withdrawal`)}
                                    </MenuItem>
                                    <MenuItem value="refund">
                                        {t(`reports.reportWalletAction.refund`)}
                                    </MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                                <TextField
                                    label={t(`reports.reportWalletAction.remark`)}
                                    variant="outlined"
                                    value={walletActionDialog.remark}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                    error={inputErrors && inputErrors.remark ? true : false}
                                    name="remark"
                                    onChange={({ target }) => setWalletActionDialog({ ...walletActionDialog, remark: target.value })}
                                    style={{ width: '100%'}}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handleWithdrawalDialogClose()}>{t('button.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={() => updateWithdrawalStatus()}>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
        );
    };    

    const columns = useMemo(() => ([
        { title: t(`reports.reportWalletAction.actionId`), field: 'id' },
        { title: t(`user.username`), field: 'wallet_actionable.username', render: row => row.wallet_actionable?.username, sorting: false, filtering: false },
        { 
            title: t(`reports.reportWalletAction.actionType`), 
            field: 'actionable_type', 
            sorting: false,
            render: (rowData) => {
                return rowData.actionable_type ? rowData.actionable_type.replace('App\\', '') : '';
            }
        },
        {
            title: t(`reports.reportWalletAction.originalAmount`),
            field: 'original_amount',
            render: row => parseFloat(row.original_amount).toFixed(4),
        },
        {
            title: t(`reports.reportWalletAction.finalAmount`),
            field: 'final_amount',
            render: row => parseFloat(row.final_amount).toFixed(4),
        },
        {
            title: t(`reports.reportWalletAction.details`),
            field: 'details',
            render: row => (
                <Box>
                    {_.map(row.details, (content, title) => {
                        if (_.includes(['from', 'to', 'address', 'txid'], title)) {
                            return (
                                <Box key={title}>
                                    {t(`wallets.${title}`) + ": " + content}
                                </Box>
                            );
                        }
                        return null;
                    })}
                </Box>
            ),
            hideExport: false,
            sorting: false,
            filtering: false
        },
        {
            title: t(`general.admin_remark`),
            field: 'remark.admin_remark',
            render: row => row.remark?.admin_remark ? row.remark.admin_remark : '-',
            sorting: false
        },
        {
            title: t(`general.member_remark`),
            field: 'remark.member_remark',
            render: row => row.remark?.member_remark ? row.remark.member_remark : '-',
            sorting: false
        },        
        {
            title: t('reports.reportWalletAction.status'),
            field: 'status',
            render: row => row.transaction_code_display[i18n.language] ?? row.transaction_code_display.en
        },        
        { title: t(`general.updatedAt`), field: 'updated_at' },
    ]), [i18n.language, t, statusList]);

      
    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography variant="h4" component="h1">{t(`title.bicpayWithdrawal`)}</Typography>
                </Box>
                {/* <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="textPrimary">{t(`title.bicpayWithdrawal`)}</Typography>
                </Breadcrumbs> */}
            </Grid>
            {
                value == 'complated' &&
                    <Box style={{ marginTop: 20, marginBottom: 20, fontSize: 12 }}>
                        <Typography>{t('reports.reportWalletAction.totalOriginalAmount')}: {originalAmount}</Typography>
                        <Typography>{t('reports.reportWalletAction.totalFinalAmount')}: {finalAmount}</Typography>
                    </Box>
            }

            <Tabs value={value} onChange={handleChange} aria-label="status tab" style={{ marginBottom: 20 }}>
                <Tab value="all" label={t(`withdrawal.all`)} icon={<ReorderIcon />}/>
                <Tab value="pending" label={t(`withdrawal.pending`)} icon={<ScheduleIcon />}/>
                <Tab value="process" label={t(`withdrawal.processing`)} icon={<HourglassEmptyIcon />}/>
                <Tab value="cancel" label={t(`withdrawal.cancelled`)} icon={<CancelOutlinedIcon />}/>
                <Tab value="complated" label={t(`withdrawal.completed`)} icon={<CheckCircleOutlineOutlinedIcon />}/>
                <Tab value="refund" label={t(`title.refunds`)} icon={<ReplayIcon />}/>
            </Tabs>

            <MaterialTable
                title={t('title.bicpayWithdrawal')}
                columns={columns}
                data={data}
                isLoading={tableLoading}
                onSelectionChange={(rows) => setSelectedRows(rows)}
                options={{
                    search: false,
                    pageSize: 10,
                    selection: value == 'pending' ? true : false,
                    filtering: false,
                    exportButton: true,
                    exportFileName: `BicpayWithdrawal_${new Date().toISOString().slice(0, 10)}`,
                }}
                actions={
                    value == 'pending' ?
                    [{
                        tooltip: t(`button.update`),
                        icon: 'edit',
                        onClick: handleOpenDialogWithSelected,
                        disabled: selectedRows.length === 0,
                    }]:[]
                }
                style={{ ...theme.box1, ...theme.p20 }}
                localization={{
                    pagination: {
                        labelDisplayedRows: t('table.labelDisplayedRows'),
                        labelRowsSelect: t('table.rows'),
                        firstTooltip: t('table.firstPage'),
                        previousTooltip: t('table.previousPage'),
                        nextTooltip: t('table.nextPage'),
                        lastTooltip: t('table.lastPage'),
                    },
                    toolbar: {
                        searchTooltip: t('table.search'),
                        searchPlaceholder: t('table.search'),
                        nRowsSelected: t('table.rowsSelected'),
                        exportTitle: t('table.export'),
                        exportPDFName: t('table.exportPDF'),
                        exportCSVName: t('table.exportCSV')
                    },
                    body: {
                        emptyDataSourceMessage: t('table.noRecord'),
                    }
                }}
            />
            {updateStatusDialog()}
        </>
    )
}
//     paper: {
//         width: '100%',
//         marginBottom: theme.spacing(2),
//     },
//     searchRoot: {
//         padding: '2px 4px',
//         display: 'flex',
//         alignItems: 'center',
//         width: 400,
//     },
//     searchInput: {
//         marginLeft: theme.spacing(1),
//         flex: 1,
//     },
//     iconButton: {
//         padding: 10,
//     },
//     divider: {
//         height: 28,
//         margin: 4,
//     },
//     toolbarRoot: {
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(1),
//         flexDirection: 'row-reverse'
//     },
// }));