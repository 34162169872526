import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, putUrl, deleteUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Card, CardContent, CardMedia, Chip, FormControlLabel, Switch, AppBar, Tabs, Tab, Tooltip, IconButton, Box, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import PropTypes from 'prop-types';

import ProductEditGeneral from './ProductEditGeneral';
import ProductEditOptions from './ProductEditOptions';
import ProductEditMultiPricings from './ProductEditMultiPricings';
import ProductEditPointRewards from './ProductEditPointRewards';
import ProductEditHistory from './ProductEditHistory';
import ProductEditShippingRegion from './ProductEditShippingRegion';
import theme from '../../Theme';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Paper className={classes.paper}>{children}</Paper>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

export default function ProductEdit() {
    const classes = useStyles();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useParams();
    const { role, permissions, isAdmin, isMerchant } = useSelector(state => state.general);
    const [data, setData] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [currencyDisplay, setCurrencyDisplay] = useState([]);
    const [dialog, setDialog] = useState({
        open: false,
        action: ''
    });

    let match1 = useRouteMatch("/products/:id/options");
    let match2 = useRouteMatch("/products/:id/multi_pricing");
    let match3 = useRouteMatch("/products/:id/point_reward");
    let match4 = useRouteMatch("/products/:id/point_history");
    let match5 = useRouteMatch("/products/:id/shipping_region");
    let history = useHistory();

    const [pointList, setPointList] = useState([]);
    const [pointData, setPointData] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [mranks, setMRanks] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [shippingRegions, setShippingRegions] = useState([]);

    const [claimInfo, setClaimInfo] = useState({
        mallComm: 0,
        sst: 0,
        walletDistribute: []
    });

    function callAllApi(){

        getUrl(`admin/products/${id}`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setData(result.data);
                setShippingRegions(result.data.shipping_region_restriction);
                let currency_translate = _.split(result.data.currency_display, '|');
                setCurrencyDisplay(currency_translate);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        getUrl(`admin/categories`).then(response => {
            if(response.status) {
                console.log("refetch", response.data);
                setCategoryProducts(response.data);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        if(isAdmin){
            getUrl(`admin/companies_by_roles`).then(result => {
                if(result.status === 1 && isMountedRef.current) {
                    setCompanies(result.data);
                }
            }).catch((error) => {
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            })
        }

        getUrl(`admin/point_list`).then(result => {
            if(result.status === 1 && isMountedRef.current) {
                setPointList(result.data);
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        if(_.includes(permissions, "rank")){
            getUrl('admin/grouped_rank_list').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    let tempRank = response.rank;
                    tempRank.push({id: 0, name: t('rank.noRank')});
                    setRanks(tempRank);
                    // set mrank
                    let tempMRank = response.mrank;
                    tempMRank.push({id: 0, name: t('rank.noRank')});
                    setMRanks(tempMRank);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }else{
            let tempRank = [];
            tempRank.push({id: 0, name: t('rank.noRank')});
            setRanks(tempRank);
            setMRanks(tempRank);
        }

        getUrl(`admin/address`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
            } else {
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        getUrl(`admin/preview-order-claim`).then(response => {
            if(response.status) {
                setClaimInfo({
                    mallComm: response.mall_commission,
                    sst: response.sst,
                    walletDistribute: response.order_claim_wallet
                });
            }
        }).catch((error) => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

    }

    useEffect(() => {
        isMountedRef.current = true;
        callAllApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const changeStatus = (event) => {
        setLoading(true);
        putUrl(`admin/products/${data.id}/status`, {status: event.target.checked ? 1 : 0 })
        .then((response) => {
            setLoading(false);
            let {status, message, data:newData} = response;
            if(status === 1){
                setData({ ...data, ['status']: newData.status ,['status_display_en']: newData.status_display_en, ['status_display_cn']: newData.status_display_cn});
                addAlert('', message, 'success', '');
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    };

    const [tabValue, setTabValue] = useState(match5? "f": (match4 ? "e" :(match3 ? "d" :(match2 ? "c" : (match1 ? "b" : "a")))));
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleDialogOpen = (action) => {
        setDialog({
            open: true,
            action: action
        });
    }
    const handleDialogClose = () => {
        setDialog({
            open: false,
            action: ''
        });
    }

    // const duplicateProduct = () => {
    //     handleDialogClose();
    //     getUrl(`admin/products/${data.id}/duplicate`).then(response => {
    //         if(isMountedRef.current) {
    //             if(response.status === 1){
    //                 if(response.new_id){
    //                     let msg = t('snackbarMsg.duplicateSuccess') + ' ID: ' + response.new_id ;
    //                     addAlert('', msg, 'success', '');
    //                     window.open("/products/"+response.new_id, "_blank");
    //                 }else{
    //                     let msg = t('snackbarMsg.duplicateSuccess');
    //                     addAlert('', msg, 'success', '');
    //                 }
    //             }else{
    //                 if(response.data){
    //                     let msg = response.data;
    //                     addAlert('', msg, 'error', '');
    //                 }else{
    //                     let msg = t('snackbarMsg.duplicateError');
    //                     addAlert('', msg, 'error', '');
    //                 }
    //             }
    //         }
    //     }).catch((error) => {
    //         let msg = error+"\n"+t('error.contactSupport');
    //         addAlert('', msg, 'error', '');
    //     });
    // }

    // const deleteDuplicating = () => {
    //     setLoading(true);
    //     deleteUrl(`admin/products/${id}/duplicate`).then((response) => {
    //         setLoading(false);
    //         if(response.status === 1){
    //             addAlert('', t('snackbarMsg.deleteSuccess'), 'success', '');
    //             history.push('/products');
    //         }else{
    //             if(response.data){
    //                 addAlert('', response.data, 'error', '');
    //             }else{
    //                 addAlert('', t('snackbarMsg.deleteError'), 'error', '');
    //             }
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
    //     });
    // }

    const deleteProduct = () => {
        setLoading(true);
        putUrl(`admin/products/${data.id}/status`, {status: 3 })
        .then((response) => {
            setLoading(false);
            handleDialogClose();
            let {status, message, data:newData} = response;
            if(status === 1){
                setData({ ...data, ['status']: newData.status ,['status_display_en']: newData.status_display_en, ['status_display_cn']: newData.status_display_cn});
                addAlert('', message, 'success', '');
                history.push('/products');
            }else{
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('product.editProduct')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/products`} component={RouterLink}>{t('product.products')}</Link>
                        <Typography style={{ color: 'black' }}>{t('product.editProduct')}</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.cardRoot} style={{...theme.p20,...theme.box1}}>
                    {_.size(data) > 0 && data.images_array[0] ?
                        <CardMedia
                            className={classes.cover}
                            image={data.images_array[0]}
                            title="Product Image"
                        ></CardMedia> : null
                    }
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h6">{ i18n.language === 'cn' ? (data.title_cn ? data.title_cn : data.title_en) : data.title_en }</Typography>
                            <div style={{marginTop:'8px'}}>
                                <Chip variant="outlined" size="small" label={`${data.review_count ? data.review_count : 0} ${t('product.review')}`} style={{ marginRight: 10 }} />
                                <Chip variant="outlined" size="small" icon={<FavoriteIcon fontSize="small" style={{ color: "indianred" }} />} label={`${data.wishlist_count ? data.wishlist_count : 0}`} style={{ marginRight: 10 }} />
                                <Chip variant="outlined" size="small" label={data.source_display} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'10px' }}>
                                <Typography variant="subtitle1" color="primary" style={{fontSize:"25px"}}>{ i18n.language === 'cn' ? (currencyDisplay[1] ? currencyDisplay[1] : currencyDisplay[0]) : currencyDisplay[0] }{data.sell_price}</Typography>
                                {
                                    data.status !== -1 && data.status !== 3
                                    ?
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isMerchant ? (data.status ? true : false) : (data.status === 0 || data.status === 2 ? false : true)}
                                                onChange={(event) => changeStatus(event)}
                                                name="status"
                                                color="primary"
                                            />
                                        }
                                        label={data[`status_display_${i18n.language}`]}
                                    />
                                    :
                                    (
                                        // data.status === -1
                                        // ?
                                        // <Box>
                                        //     <IconButton color="primary" onClick={() => handleDialogOpen('deleteDuplicate')}>
                                        //         <DeleteIcon />
                                        //     </IconButton>
                                        //     <Typography variant="body1">{data[`status_display_${i18n.language}`]}</Typography>
                                        // </Box>
                                        // :
                                        data.status === 3
                                        ?
                                        <Typography variant="body1">{data[`status_display_${i18n.language}`]}</Typography>
                                        :
                                        null
                                    )
                                }
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'10px' }}>
                                {
                                    isAdmin && data.status !== -1 && data.status !== 3
                                    ?
                                    <Tooltip title={t('product.deleteProduct')}>
                                        <IconButton variant="outlined" color="primary" onClick={() => handleDialogOpen('deleteProduct')}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                                }
                            </div>
                        </CardContent>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.tabRoot}>
                    <AppBar position="static">
                        <Tabs
                            variant="fullWidth"
                            value={tabValue}
                            onChange={handleChange}
                            aria-label="nav tabs"
                        >
                            <Tab label={t('product.general')} value="a" {...a11yProps("a")} to={`/products/${id}/general`} component={RouterLink}/>
                            <Tab label={t('product.options')} value="b" {...a11yProps("b")} to={`/products/${id}/options`} component={RouterLink}/>
                            { _.includes(permissions, "product_multi_pricing") ? 
                                <Tab label={t('product.multiPricings')} value="c" {...a11yProps("c")} to={`/products/${id}/multi_pricing`} component={RouterLink}/>
                                : null 
                            }
                            { _.includes(permissions, "product_rewards") ? 
                                <Tab label={t('product.pointRewards')} value="d" {...a11yProps("d")} to={`/products/${id}/point_reward`} component={RouterLink}/>
                                : null 
                            }
                            { _.includes(permissions, "product_history") ? 
                                <Tab label={t('log.productUpdateHistory')} value="e" {...a11yProps("e")} to={`/products/${id}/point_history`} component={RouterLink}/>
                                : null 
                            }
                            { isAdmin ?
                                <Tab label={t('product.shippingRegion')} value="f" {...a11yProps("f")} to={`/products/${id}/shipping_region`} component={RouterLink}/>
                                : null
                            }
                        </Tabs>
                    </AppBar>
                    {/* { _.size(data) > 0 && _.size(categories) > 0 ? */}
                    { _.size(data) > 0 && _.size(categoryProducts) > 0 ?
                        <>
                            <TabPanel value={tabValue} index="a"><ProductEditGeneral data={data} companies={companies} setLoading={setLoading} addAlert={addAlert} setData={setData} pointList={pointList} pointData={pointData} setPointData={setPointData} categoryProducts={categoryProducts} /*categoryTreeData={categoryTreeData} setCategoryTreeData={setCategoryTreeData}*/ wallets={wallets} mranks={mranks} ranks={ranks} isMountedRef={isMountedRef} claimInfo={claimInfo} /></TabPanel>
                            <TabPanel value={tabValue} index="b"><ProductEditOptions data={data} companies={companies} setLoading={setLoading} addAlert={addAlert} setData={setData} pointList={pointList} pointData={pointData} setPointData={setPointData} wallets={wallets} /></TabPanel>
                            { _.includes(permissions, "product_multi_pricing") ? 
                                <TabPanel value={tabValue} index="c"><ProductEditMultiPricings data={data} setLoading={setLoading} addAlert={addAlert} setData={setData} /></TabPanel>
                                : null
                            }
                            { _.includes(permissions, "product_rewards") ? 
                                <TabPanel value={tabValue} index="d"><ProductEditPointRewards data={data} setLoading={setLoading} addAlert={addAlert} setData={setData} /></TabPanel>
                                : null
                            }
                            { _.includes(permissions, "product_history") ? 
                                <TabPanel value={tabValue} index="e"><ProductEditHistory productId={data.id} /></TabPanel>
                                : null
                            }
                            { isAdmin || _.size(_.intersection(role, ['super-merchant'])) && _.size(countryList) ?
                                <TabPanel value={tabValue} index="f"><ProductEditShippingRegion id={id} countryList={countryList} shippingRegions={shippingRegions} setShippingRegions={setShippingRegions} stateGroup={stateGroup} stateList={stateList} companyId={data.merchant_company_id} setLoading={setLoading} addAlert={addAlert} /></TabPanel>
                                : null
                            }
                        </> : null
                    }
                </div>
            </Grid>
            <Dialog
                open={dialog.open}
                onClose={() => handleDialogClose()}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle>{t('dialog.reminderTitle')}</DialogTitle>
                <DialogContent>
                    {
                        // dialog.action === 'duplicate'
                        // ?
                        // <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.duplicate') })}</DialogContentText>
                        // :
                        // dialog.action === 'deleteDuplicate'
                        // ?
                        // <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.deleteDuplicate') })}</DialogContentText>
                        // :
                        dialog.action === 'deleteProduct'
                        ?
                        <DialogContentText>{t('dialog.reminderContent', { 'action': t('product.deleteProduct') })}</DialogContentText>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose()} color="secondary">{t('button.nope')}!</Button>
                    {
                        // dialog.action === 'duplicate'
                        // ?
                        // <Button onClick={() => duplicateProduct()} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                        // :
                        // dialog.action === 'deleteDuplicate'
                        // ?
                        // <Button onClick={() => deleteDuplicating()} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                        // :
                        dialog.action === 'deleteProduct'
                        ?
                        <Button onClick={() => deleteProduct()} color="primary" autoFocus>{t('button.yesPlease')}</Button>
                        :
                        null
                    }
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 220,
        aspectRatio: "1 / 1",
        objectFit: "cover",
        [theme.breakpoints.down('xs')]: {
            alignSelf: 'center',
        },
    },
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));