import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams} from 'react-router-dom';
import _ from 'lodash';
import { formatDate } from '@utils/Tools';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, Switch, Checkbox, FormControl, FormLabel, FormControlLabel, FormGroup, AppBar, Tabs, Tab, Box, MenuItem } from '@material-ui/core';
import { getUrl, putUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
// Icon
import ContactsIcon from '@material-ui/icons/Contacts';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { ArtTrackOutlined, AspectRatioOutlined } from '@material-ui/icons';
// Dialog
import { Dialog, IconButton, DialogContentText } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import MoneyIcon from '@material-ui/icons/Money';
import CreateIcon from '@material-ui/icons/Create';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function areEqualShallow(a, b) {
    for(var key in a) {
        if(a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

export default function MemberEdit() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { permissions } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [addressReminderOpen, setAddressReminderOpen] = useState(false);

    const [passwordResult, setPasswordResult] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [rolesPermissions, setRolesPermissions] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [addressInfo, setAddressInfo] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityGroup, setCityGroup] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [districtGroup, setDistrictGroup] = useState([]);
    const [addressMobileCode , setAddressMobileCode]= useState({});
    const [caUnblockTime, setCAUnblockTime] = useState(null);
    const [emptyAddress] = useState({
        id: 0,
        default: false,
        delete: false,
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        name:'',
        mobileCode:'',
        mobile:'',
        email:'',
        identity_no:'',
        ic_front:'',
        ic_back:'',

    });
    const [state, setState] = useState({
        name: '',
        username: '',
        email: '',
        nric: '',
        mobileCountry:'',
        mobileCode:'',
        mobile: '',
        role: '',
        referral: '',
        placement: '',
        position: 0,
        rankId: 0,
        crankId: 0,
        mrankId: 0,
        cmrankId: 0,
        pid: 0,
        frId: 0,
        expiryDate:"",
        crypto_address: "",
    });
    const [oldAddressInfo, setOldAddressInfo] = useState([]);
    const [passwordInfo, setPasswordInfo] = useState({
        password: '',
        passwordConfirmation: '',
        securityPassword: '',
        securityPasswordConfirmation: '',
    });
    const placementPosition = [{value: 1, label: t("profile.placementPosition.left")}, {value: 2, label: t("profile.placementPosition.right")}];
    const [ranks, setRanks] = useState([]);
    const [mranks, setMranks] = useState([]);
    const [bindSubAccount, setBindSubAccount]=useState([]);
    const [profitLimitList, setProfitLimitList]=useState([]);
    const [subAccountInfo, setSubAccountInfo]=useState({
        bindDialog:false,
        unbindPopup:false,
        bindSuccess:false,
        unbindSuccess:false,
        username:'',
        errorMsg:[],
    })
    const [ProfitLimitInfo, setProfitLimitInfo]=useState({
        editdialog:false,
        id:'',
        profit_limit :'',
        profit_limit_total :'',
        errorMsg:[],
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
        updateState:false,
    });

    let { id } = useParams();
    const icFrontRef = useRef();
    const icBackRef = useRef();

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true)
        getUrl(`mobile-code-list`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setMobileCodeList(prevList =>({...prevList , country: response?.data?.country_list}));
                }
            }

        }).catch(error => {
            // addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
    }, []);

    useEffect(() => {
        if(mobileCodeList.updateState){
            if (_.size(mobileCodeList?.country) > 0) {
                setLoading(true);
                if (state?.mobileCountry !== '') {
                    let codeList = {};
                    let countryCode = state?.mobileCode;
                    let code = _.find(mobileCodeList?.country, { 'code': state?.mobileCountry });
    
                    if (code) {
                        if (_.size(code?.tel_code) > 0) {
                            codeList = code?.tel_code;
                            countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : '';
                        }
                    }
    
                    setMobileCodeList(prevList =>({...prevList , code: codeList , updateState:false }));
                    setState(prevState => ({ ...prevState, mobileCode: countryCode}));
                } else if (state?.mobileCountry === '' && state?.mobileCode !== '') {
                    let tempArr = _.clone(mobileCodeList?.country);
    
                    tempArr = tempArr.filter((item) => _.includes(item?.tel_code, state?.mobileCode));
                    if (_.size(tempArr) > 0) {
                        setMobileCodeList(prevList =>({...prevList , code: tempArr[0]['tel_code'], updateState:false }));
                        setState(prevState => ({ ...prevState, mobileCountry: tempArr[0]['code']}));
                    }
                }
                setLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [mobileCodeList, mobileCodeList.updateState]);

    useEffect(() => {
        if (_.size(countryList) > 0) {
            setLoading(true);
            if (addressInfo?.country !== '') {
                let codeList = {};
                let countryCode = addressInfo?.mobile_country_code;
                let code = _.find(countryList, { 'code': addressInfo?.country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : '';
                    }
                }

                setAddressMobileCode(codeList);
                setAddressInfo(prevState => ({ ...prevState, mobile_country_code: countryCode}));
            } else if (addressInfo?.country === '' && addressInfo?.mobile_country_code !== '') {
                let tempArr = _.clone(countryList);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, addressInfo?.mobile_country_code));
                if (_.size(tempArr) > 0) {
                    setAddressMobileCode(tempArr[0]['tel_code']);
                    setAddressInfo(prevState => ({ ...prevState, country: tempArr[0]['code']}));
                }
            }
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [addressInfo?.country, addressInfo?.mobileCode]);

    useEffect(() => {
        if(addressInfo.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressInfo.country])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handlePermissionsChange = (event) => {
        setAllPermissions({ ...allPermissions, [event.target.name]: {
            ...allPermissions[event.target.name],
            isChecked: event.target.checked,
        }});
    };
    const handleRoleChange = (event) => {
        let newState = allPermissions;
        Object.values(rolesPermissions[state.role]).map((value, key) => (
            newState[value] = {
                ...allPermissions[value],
                isChecked: false,
                isDisabled: false,
            }
        ));
        Object.values(rolesPermissions[event.target.value]).map((value, key) => (
            newState[value] = {
                ...allPermissions[value],
                isChecked: true,
                isDisabled: true,
            }
        ));
        setState({ ...state, role: event.target.value });
        setAllPermissions(newState);
    };
    const handleDialogOpen = (event) => {
        setDialogOpen(true);
        let address = event;
        address.delete = false;
        setAddressInfo(address);
        setOldAddressInfo(address);
        setInputErrors("");
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setAddressReminderOpen(false);
        setAddressMobileCode({});
    };
    const dialogCloseReminder = () => {
        if(!areEqualShallow(addressInfo, oldAddressInfo)){
            setAddressReminderOpen(true);
        }else{
            setDialogOpen(false);
        }
    };
    const handleDeletePopupOpen = (event) => {
        let address = event;
        address.delete = true;
        setAddressInfo(address);
        setPopupOpen(true);
    };
    const handleDeletePopupClose = () => {
        setAddressInfo({ ...addressInfo, delete: false });
        setPopupOpen(false);
    };
    const handleAddressPopupClose = (event) => {
        setAddressReminderOpen(false);
        setAddressMobileCode({});
    };

    const handleBindDialogOpen = () => {
        setSubAccountInfo({ ...subAccountInfo, bindDialog: true, bindSuccess:false, unbindSuccess:false, errorMsg:[] });
    };

    const handleBindDialogClose = () => {
        setSubAccountInfo({ ...subAccountInfo, bindDialog: false, username:null, bindSuccess:false, unbindSuccess:false, errorMsg:[]  });
       
    };
    const handleUnbindPopupOpen =(event)=>{
        setSubAccountInfo({ ...subAccountInfo, unbindPopup: true, username:event.username , bindSuccess:false, unbindSuccess:false, errorMsg:[] });
    }

    const handleUnbindPopupClose = () => {
        setSubAccountInfo({ ...subAccountInfo, unbindPopup: false, username:null , bindSuccess:false, unbindSuccess:false, errorMsg:[] });
    };
    const handleProfitLimitOpen =(event)=>{
        setInputErrors("");
        setProfitLimitInfo({ ...ProfitLimitInfo, editdialog: true, id:event.id, profit_limit:event.profit_limit, profit_limit_total:event.profit_limit_total });
    }
    const handleProfitLimitClose = () => {
        setProfitLimitInfo({ ...ProfitLimitInfo, editdialog: false, id:'', profit_limit:'', profit_limit_total:''  });
    };
    
    const bindAccount = () => {
        setLoading(true);
        let params={
            username:subAccountInfo.username,
        }

        postUrl(`admin/members/${id}/bind`, params).then(response => {
            setLoading(false);
            let { status, data, error, message } = response;
            if (status === 1) {
               setSubAccountInfo({...subAccountInfo , bindSuccess:true});

            } else {
                if (error) {
                    let allErrorField = [];
                    _.map(error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setSubAccountInfo({...subAccountInfo , bindSuccess:false, errorMsg:allErrorField});

                }
            }

        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const unbindAccount = () => {
        setLoading(true);
        let params={
            username:subAccountInfo.username,
        }

        putUrl(`admin/members/${id}/unbind`, params).then(response => {
            setLoading(false);
            let { status, data, error, message } = response;
            if (status === 1) {
                setSubAccountInfo({...subAccountInfo , unbindSuccess:true});

            } else {
                if(error){
                    let msg = error+"\n"+t('error.contactSupport');
                     addAlert('', msg, 'error', '');
                }
            }

        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if(name === 'ic_front') {
            if(icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if(name === 'ic_back') {
            if(icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        setAddressInfo({ ...addressInfo, [name]: value });
    };
    
    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/members/${id}`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setState(prevState => ({ 
                    ...prevState, 
                    name: response.data.name,
                    username: response.data.username?response.data.username:'',
                    email: response.data.email?response.data.email:'',
                    mobileCode: response.data.mobile_country_code?response.data.mobile_country_code:'',
                    mobile: response.data.mobile?response.data.mobile:'',
                    nric: response.data.nric?response.data.nric:'',
                    role: response.data.user_role,
                    referral: response.data.genealogy ? response.data.genealogy.referral_username: "",
                    placement: response.data.genealogy ? response.data.genealogy.placement_username: "",
                    position: response.data.genealogy ? response.data.genealogy.position: 0,
                    rankId: response.data.rank_id ? response.data.rank_id : 0,
                    crankId: response.data.crank_id ? response.data.crank_id : 0,
                    mrankId: response.data.mrank_id ? response.data.mrank_id : 0,
                    cmrankId: response.data.cmrank_id ? response.data.cmrank_id : 0,
                    pid: response.data.pid ? response.data.pid : 0,
                    frId: response.data.fr_id ? response.data.fr_id : 0,
                    expiryDate:response.data.expiry_date? formatDate(response.data.expiry_date):"",
                    bank_name: response.data.bank_name?response.data.bank_name:'',
                    bank_holder_name: response.data.bank_holder_name?response.data.bank_holder_name:'',
                    bank_account: response.data.bank_account?response.data.bank_account:'',
                    bank_swift: response.data.bank_swift?response.data.bank_swift:'',
                    crypto_address: response?.data?.wallets?.crypto?.crypto_address?response?.data?.wallets?.crypto?.crypto_address:'',
                }));
                setMobileCodeList(prevList =>({...prevList , updateState: true }));
                setAllRoles(response.all_roles);
                setAllPermissions(response.all_permissions);
                setRolesPermissions(response.roles_permission);
                setAddresses(response.addresses);
                setBindSubAccount(response.data.bind_accounts);
                setCAUnblockTime(response?.data?.wallets?.crypto?.ca_unblock_time ? moment(response?.data?.wallets?.crypto?.ca_unblock_time).format("YYYY-MM-DDTHH:mm") : null);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        getUrl(`admin/address`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
                setCityList(response.city_list);
                setCityGroup(response.city_group);
                setDistrictList(response.district_list);
                setDistrictGroup(response.district_group);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        getUrl(`admin/members/${id}/rank_list`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                let tempRank = response.rank;
                tempRank.push({id: 0, name: t('rank.noRank')});
                setRanks(tempRank);
                let tempMRank = response.mrank;
                tempMRank.push({id: 0, name: t('rank.noRank')});
                setMranks(tempMRank);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });

        get_profit_limit_list();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert,subAccountInfo.bindSuccess, subAccountInfo.unbindSuccess]);

    function get_profit_limit_list(){
        getUrl(`admin/members/${id}/profit_order`).then(response =>{
            if(response.status === 1 && isMountedRef.current) {
                setProfitLimitList(response.data)
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        let updateData = '';
        if(tabValue === 0){
            updateData = {
                update_tab: 'basic',
                name: state.name,
                username: state.username,
                mobile_country: state.mobileCountry,
                mobile_code: state.mobileCode,
                mobile: state.mobile,
                nric: state.nric,
                email: state.email,
                referral: state.referral,
                placement: state.placement,
                position: state.position>0?state.position:null,
                crank_id: state.crankId,
                cmrank_id: state.cmrankId,
                expiry_date:state.expiryDate,
                bank_name: state.bank_name,
                bank_holder_name: state.bank_holder_name,
                bank_account: state.bank_account,
                bank_swift: state.bank_swift,
                crypto_address: state.crypto_address,
                ca_unblock_time: caUnblockTime,
            };
        }else if(tabValue === 2){
            updateData = {
                update_tab: 'addresses',
                id: addressInfo.id,
                default: addressInfo.default,
                delete: addressInfo.delete,
                country: addressInfo.country,
                zip: addressInfo.zip,
                state: addressInfo.state,
                city: addressInfo.city,
                address2: addressInfo.address2,
                address: addressInfo.address,
                recipient:addressInfo.name || "",
                recipient_mobile_code: addressInfo.mobile_country_code || "",
                recipient_mobile:addressInfo.mobile || "",
                recipient_email:addressInfo.email || "",
                identity_no:addressInfo.identity_no || "",
                icFront:addressInfo.ic_front ? addressInfo.ic_front.file_name?  addressInfo.ic_front.file_name:addressInfo.ic_front:'',
                icBack:addressInfo.ic_back ? addressInfo.ic_back.file_name?  addressInfo.ic_back.file_name:addressInfo.ic_back:'',
            };
        }else if(tabValue === 3){
            updateData = {
                update_tab: 'permissions',
                role: state.role,
                permissions: allPermissions,
            };
        }
        // console.log("tabValue", tabValue);
        // console.log("updateData", updateData);
        putUrl(`admin/members/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
                if(response.addresses){
                    setAddresses(response.addresses);
                }
                if(tabValue === 1){
                    setOldAddressInfo(addressInfo);
                }
                setDialogOpen(false);
                setPopupOpen(false);
                setAddressMobileCode({});
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    const sendResetPasswordLink = () => {
        setLoading(true);
        setPasswordResult('');
        postUrl(`admin/merchants/${id}/reset-password`)
        .then(response => {
            setLoading(false);
            setPasswordResult(response.status ? '':response.message);
            if(response.status) {
                let msg = t('user.resetPasswordSend');
                addAlert('', msg, 'success', '');
            }
        }).catch(error => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const update_password = () => {
        setLoading(true);
        setInputErrors("");
        let updateData = {
            password: passwordInfo.password,
            password_confirmation: passwordInfo.passwordConfirmation,
        };
        putUrl(`admin/members/${id}/password`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const update_security_password = () => {
        setLoading(true);
        setInputErrors("");
        let updateData = {
            security_password: passwordInfo.securityPassword,
            security_password_confirmation: passwordInfo.securityPasswordConfirmation,
        };
        putUrl(`admin/members/${id}/security_password`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const update_profit_limit = () => {
        setLoading(true);
        let params={
            profit_limit:ProfitLimitInfo.profit_limit,
            profit_limit_total:ProfitLimitInfo.profit_limit_total,
        }
        putUrl(`admins/update/${ProfitLimitInfo.id}/profit_limit`,params).then(response =>{
            setLoading(false);
            if(response.status === 1){
                get_profit_limit_list();
                let msg = response.data;
                addAlert('', msg, 'success', '');
                setProfitLimitInfo({ ...ProfitLimitInfo, editdialog: false, id:'', profit_limit:'', profit_limit_total:''  });
            }
            if(response.errors){
                setInputErrors(response.errors);
            }

        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'mobileCountry':
            case 'mobileCode':
                setMobileCodeList(prevList =>({...prevList , updateState: true }));
                setState({ ...state, [name]: value });
                break;
            case 'mobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setState({ ...state, [name]: value });
                break;
            case 'addressMobile':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setAddressInfo({ ...addressInfo, mobile: value })
                break;
            case 'addressZip':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                setAddressInfo({ ...addressInfo, zip: value })
                break;
            default:
                setState({ ...state, [name]: value });
        }
    };

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('member.editMember')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/members`} component={RouterLink}>{t('member.members')}</Link>
                        <Typography style={{ color: 'black' }}>{t('member.editMember')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:theme.palette.primary.main,WebkitBoxShadow:'none'}}>
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                    <Tab icon={<ContactsIcon />} label={t('user.basic')} {...a11yProps(0)} value={0}/>
                    <Tab icon={<LockIcon />} label={t('user.password')} {...a11yProps(1)} value={1}/>
                    <Tab icon={<HomeWorkIcon />} label={t('user.address')} {...a11yProps(2)} value={2}/>
                    <Tab icon={<PlaylistAddCheckIcon />} label={t('user.permissions')} {...a11yProps(3)} value={3}/>
                    {
                        state.pid === 0 && state.frId === 0
                        ?
                        <Tab icon={<AccountTreeIcon />} label={t('user.bindSubAccount')} {...a11yProps(4)} value={4}/>
                        :
                        null
                    }
                    {
                        _.includes(permissions, "profit_limit") ?
                        <Tab icon={<MoneyIcon />} label={t('user.profitLimit')} {...a11yProps(5)} value={5}/>
                        :
                        null
                    }
                    
                </Tabs>
            </AppBar>
            <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="email" 
                                label={t('profile.email')} 
                                variant="outlined" 
                                fullWidth
                                value={state.email}
                                helperText={inputErrors.email?inputErrors.email:''}
                                error={inputErrors.email?true:false}
                                name="email"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label={t('profile.name')} 
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name?inputErrors.name:''}
                                error={inputErrors.name?true:false}
                                name="name"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="username" 
                                label={t('profile.username')} 
                                variant="outlined" 
                                fullWidth 
                                value={state.username}
                                helperText={inputErrors.username?inputErrors.username:''}
                                error={inputErrors.username?true:false}
                                name="username"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label={t('profile.country')}
                                value={state.mobileCountry || ''}
                                name="mobileCountry"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                error={inputErrors?.mobile_country ? true : false}
                                helperText={inputErrors?.mobile_country}
                            >
                                {
                                    _.size(mobileCodeList?.country) > 0 ?
                                     _.map(mobileCodeList?.country, (option, key) => {
                                        return (
                                            <MenuItem key={key} value={option.code} style={{ color: '#222' }}>
                                                {i18n.language === 'cn' ? option.name_cn : option?.name}
                                            </MenuItem>
                                        )
                                    }):
                                    <MenuItem value=""  disabled >{`${t('general.pleaseSelect')} ${t('profile.country')}`}</MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label={t('profile.mobileCode')}
                                value={state.mobileCode}
                                name="mobileCode"
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                error={inputErrors?.mobile_code ? true : false}
                                helperText={inputErrors?.mobile_code? inputErrors?.mobile_code: (state?.mobileCode > 0 ? '': t('general.pleaseSelectCountry'))}
                                disabled={_.size(mobileCodeList?.code) > 0  ? false:true}
                            >
                                {
                                    _.size(mobileCodeList?.code) > 0 ?
                                    _.map(mobileCodeList?.code, (option, key) => {
                                        return (
                                            <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                                {option}
                                            </MenuItem>
                                        )
                                    }):
                                    <MenuItem value="" disabled >{`${t('general.pleaseSelect')} ${t('profile.mobileCode')}`}</MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="mobile" 
                                label={t('profile.mobile')} 
                                variant="outlined" 
                                fullWidth 
                                value={state.mobile}
                                helperText={inputErrors.mobile?inputErrors.mobile:''}
                                error={inputErrors.mobile?true:false}
                                name="mobile"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="nric" 
                                label={t('kyc.nric')} 
                                variant="outlined" 
                                fullWidth 
                                value={state.nric}
                                helperText={inputErrors.nric?inputErrors.nric:''}
                                error={inputErrors.nric?true:false}
                                name="nric"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="referral" 
                                label={t('profile.referral')} 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{
                                    readOnly: _.includes(permissions, "member_referral") ? false : true
                                }}
                                value={state.referral}
                                helperText={inputErrors.referral?inputErrors.referral:''}
                                error={inputErrors.referral?true:false}
                                name="referral"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="placement" 
                                label={t('profile.placement')} 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{
                                    readOnly: _.includes(permissions, "member_referral") ? false : true
                                }}
                                value={state.placement}
                                helperText={inputErrors.placement?inputErrors.placement:''}
                                error={inputErrors.placement?true:false}
                                name="placement"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="position" 
                                label={t('profile.position')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.position}
                                helperText={inputErrors.position?inputErrors.position:''}
                                error={inputErrors.position?true:false}
                                name="position"
                                onChange={handleChange}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            <option key='' value={0} disabled = {_.includes(permissions, "member_referral") ? false : true}>
                                {t('general.pleaseSelect')}
                            </option>
                            {placementPosition.map((value, key) => (
                                <option key={key} value={value.value} disabled={_.includes(permissions, "member_referral") ? false : true}>
                                    {value.label}
                                </option>
                            ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                _.map(ranks, rank => {
                                    if(rank.id === state.rankId){
                                        let name = _.split(rank.name, '|');
                                        return (
                                            <TextField 
                                                id="rank" 
                                                label={t('profile.rank')} 
                                                variant="outlined" 
                                                fullWidth 
                                                value={ i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                                readOnly
                                                key={rank.id}
                                            />
                                        )
                                    }
                                })
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="crank" 
                                label={t('profile.crank')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.crankId}
                                helperText={inputErrors.crank_id?inputErrors.crank_id:''}
                                error={inputErrors.crank_id?true:false}
                                onChange={({ target }) => setState({ ...state, crankId: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {ranks.map((rank, key) => {
                                let name = _.split(rank.name, '|');
                                return(
                                    <option key={key} value={rank.id} disabled={_.includes(permissions, "member_edit_crank") ? false : true}>
                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                    </option>
                                )
                            })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {
                                _.map(mranks, mrank => {
                                    if(mrank.id === state.mrankId){
                                        let name = _.split(mrank.name, '|');
                                        return (
                                            <TextField 
                                                id="mrank" 
                                                label={t('profile.mrank')} 
                                                variant="outlined" 
                                                fullWidth 
                                                value={ i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                                readOnly
                                                key={mrank.id}
                                            />
                                        )
                                    }
                                })
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="cmrank" 
                                label={t('profile.cmrank')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.cmrankId}
                                helperText={inputErrors.cmrank_id?inputErrors.cmrank_id:''}
                                error={inputErrors.cmrank_id?true:false}
                                onChange={({ target }) => setState({ ...state, cmrankId: target.value }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {mranks.map((mrank, key) => {
                                let name = _.split(mrank.name, '|');
                                return(
                                    <option key={key} value={mrank.id} disabled={_.includes(permissions, "member_edit_crank") ? false : true}>
                                        { i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0] }
                                    </option>
                                )
                            })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="expiryDate" 
                            fullWidth
                            label={t('profile.expiryDate')} 
                            variant="outlined" 
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={state.expiryDate ? state.expiryDate  : ""}
                            helperText={inputErrors.expiry_date  ? inputErrors.expiry_date[0]: ''}
                            error={inputErrors.expiry_date  ? true : false}
                            name="expiryDate"
                            onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="bankName" 
                            fullWidth
                            label={t('profile.bankName')} 
                            variant="outlined" 
                            value={state.bank_name ? state.bank_name  : ""}
                            helperText={inputErrors.bank_name  ? inputErrors.bank_name[0]: ''}
                            error={inputErrors.bank_name  ? true : false}
                            name="bank_name"
                            onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="bankHolderName" 
                            fullWidth
                            label={t('profile.bankHolderName')} 
                            variant="outlined" 
                            value={state.bank_holder_name ? state.bank_holder_name  : ""}
                            helperText={inputErrors.bank_holder_name  ? inputErrors.bank_holder_name[0]: ''}
                            error={inputErrors.bank_holder_name  ? true : false}
                            name="bank_holder_name"
                            onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="bankAccount" 
                            fullWidth
                            label={t('profile.bankAccount')} 
                            variant="outlined" 
                            value={state.bank_account ? state.bank_account  : ""}
                            helperText={inputErrors.bank_account  ? inputErrors.bank_account[0]: ''}
                            error={inputErrors.bank_account  ? true : false}
                            name="bank_account"
                            onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            id="bankSwift" 
                            fullWidth
                            label={t('profile.bankSwift')} 
                            variant="outlined" 
                            value={state.bank_swift ? state.bank_swift  : ""}
                            helperText={inputErrors.bank_swift  ? inputErrors.bank_swift[0]: ''}
                            error={inputErrors.bank_swift  ? true : false}
                            name="bank_swift"
                            onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="cryptoAddress" 
                                fullWidth
                                label={t('profile.cryptoAddress')} 
                                variant="outlined" 
                                value={state.crypto_address ? state.crypto_address  : ""}
                                helperText={inputErrors.crypto_address  ? inputErrors.crypto_address: ''}
                                error={inputErrors.crypto_address  ? true : false}
                                name="crypto_address"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="caUnblockTime"
                                fullWidth
                                label={t('profile.caUnblockTime')}
                                variant="outlined"
                                type='datetime-local'
                                value={caUnblockTime ? caUnblockTime : ""}
                                helperText={inputErrors.ca_unblock_time ? inputErrors.ca_unblock_time : ''}
                                error={inputErrors.ca_unblock_time ? true : false}
                                name="ca_unblock_time"
                                onChange={(event) => {
                                    const selectedDateTime = event.target.value;
                                    setCAUnblockTime(selectedDateTime);
                                }}
                                InputLabelProps={{
                                    shrink: true,  // Ensure the label doesn’t overlap the placeholder
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained"  size="medium" color="primary" onClick={form_submit} >{t('button.save')}</Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Button variant="contained" onClick={ sendResetPasswordLink }>
                            <Typography variant="button">{ t('user.sendResetPasswordLink') }</Typography>
                        </Button>
                        <Typography variant="body2" color="textPrimary" style={{ marginTop: 10 }}>{ passwordResult }</Typography>
                    </Box>
                    {
                        _.includes(permissions, "change_user_password")
                        ?
                        <Grid container spacing={3}>
                            <Grid item container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="password" 
                                        label={t('user.password')} 
                                        variant="outlined" 
                                        fullWidth
                                        value={passwordInfo.password}
                                        helperText={inputErrors.password?inputErrors.password:''}
                                        error={inputErrors.password?true:false}
                                        onChange={(event) => setPasswordInfo({...passwordInfo, password: event.target.value})}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="passwordConfirmation" 
                                        label={t('user.passwordConfirmation')} 
                                        variant="outlined" 
                                        fullWidth
                                        value={passwordInfo.passwordConfirmation}
                                        helperText={inputErrors.password_confirmation?inputErrors.password_confirmation:''}
                                        error={inputErrors.password_confirmation?true:false}
                                        onChange={(event) => setPasswordInfo({...passwordInfo, passwordConfirmation: event.target.value})}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" size="medium" color="primary" onClick={update_password}>{t('button.update')}</Button> 
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="security_password" 
                                        label={t('user.securityPassword')} 
                                        variant="outlined" 
                                        fullWidth
                                        value={passwordInfo.securityPassword}
                                        helperText={inputErrors.security_password?inputErrors.security_password:''}
                                        error={inputErrors.security_password?true:false}
                                        onChange={(event) => setPasswordInfo({...passwordInfo, securityPassword: event.target.value})}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="securityPasswordConfirmation" 
                                        label={t('user.securityPasswordConfirmation')} 
                                        variant="outlined" 
                                        fullWidth
                                        value={passwordInfo.securityPasswordConfirmation}
                                        helperText={inputErrors.security_password_confirmation?inputErrors.security_password_confirmation:''}
                                        error={inputErrors.security_password_confirmation?true:false}
                                        onChange={(event) => setPasswordInfo({...passwordInfo, securityPasswordConfirmation: event.target.value})}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" size="medium" color="primary" onClick={update_security_password}>{t('button.update')}</Button> 
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Grid container spacing={3} direction="column">
                        {addresses.map((value, key) => (
                            <Grid item xs={12} md={6} key={value.id}>
                                <Button disabled>
                                    {value.default?<StarIcon />:<StarBorderIcon />}
                                </Button>
                                <Button onClick={({ event }) => handleDialogOpen( value ) }>
                                    <EditIcon />
                                </Button>
                                <Button onClick={({ event }) => handleDeletePopupOpen( value ) }>
                                    <DeleteForeverIcon />
                                </Button>
                                <TextField 
                                    id="addresses[]" 
                                    label={t('address.address')} 
                                    variant="outlined" 
                                    fullWidth
                                    multiline
                                    rowsMax={4}
                                    value={value.full_address}
                                    InputProps={{ readOnly: true }}
                                  />
                                  </Grid>
                        ))}
                        <Grid item xs={12} md={6}>
                            <Button onClick={({ event }) => handleDialogOpen( emptyAddress ) }><AddIcon /></Button> 
                        </Grid>
                        <Dialog onClose={dialogCloseReminder} open={dialogOpen} fullWidth>
                            <DialogTitle id={addressInfo.id} onClose={dialogCloseReminder}>
                                {
                                    addressInfo.id
                                    ?
                                    t('address.editAddress')
                                    :
                                    t('address.addNewAddress')
                                }
                            </DialogTitle>
                            <DialogContent dividers>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={addressInfo.default}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, default: target.checked }) }
                                                        name="addressed_default"
                                                        color="primary"
                                                    />
                                                }
                                                labelPlacement="start"
                                                label={t('address.default')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={t('profile.recipient')}
                                                variant="outlined"
                                                fullWidth
                                                value={addressInfo.name || ""}
                                                helperText={inputErrors.recipient ? inputErrors.recipient : ''}
                                                error={inputErrors.recipient ? true : false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, name: target.value })}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                select
                                                label={t('profile.mobileCode')}
                                                value={addressInfo.mobile_country_code || ""}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, mobile_country_code: target.value })}
                                                variant="outlined"
                                                fullWidth
                                                error={inputErrors?.recipient_mobile_code ? true : false}
                                                helperText={inputErrors?.recipient_mobile_code}
                                            >
                                                <MenuItem value="" disabled >{`${t('general.pleaseSelect')} ${t('profile.mobileCode')}`}</MenuItem>
                                                {
                                                    _.size(addressMobileCode) > 0 && _.map(addressMobileCode, (option, key) => {
                                                        return (
                                                            <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={t('profile.mobile')}
                                                variant="outlined"
                                                fullWidth
                                                value={addressInfo.mobile || ""}
                                                helperText={inputErrors.recipient_mobile ? inputErrors.recipient_mobile : ''}
                                                error={inputErrors.recipient_mobile ? true : false}
                                                onChange={handleChange}
                                                name="addressMobile"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label={t('profile.email')}
                                                variant="outlined"
                                                fullWidth
                                                value={addressInfo.email || ""}
                                                helperText={inputErrors.recipient_email ? inputErrors.recipient_email : ''}
                                                error={inputErrors.recipient_email ? true : false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, email: target.value })}
                                            />
                                        </Grid>
                                        {shouldUploadIC &&
                                            <>
                                                <Grid item>
                                                    <TextField
                                                        label={t('profile.identityNo')}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={addressInfo.identity_no  || ""}
                                                        helperText={inputErrors.identity_no ? inputErrors.identity_no : ''}
                                                        error={inputErrors.identity_no ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, identity_no: target.value })}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{t('profile.chinaRecipientNeedIC')}</Typography>
                                                    <Box display="flex" flexDirection="row">
                                                        <input hidden accept="image/*" type="file" ref={icFrontRef} name="ic_front" onChange={handleAddressChange} />
                                                        <input hidden accept="image/*" type="file" ref={icBackRef} name="ic_back" onChange={handleAddressChange} />
                                                        <Button
                                                            className={classes.uploadButtonContainer}
                                                            onClick={() => icFrontRef.current.click()}
                                                            variant="outlined"
                                                        >
                                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                                {addressInfo.ic_front ?
                                                                    <img src={addressInfo.ic_front.file_name? addressInfo.ic_front.file_name:addressInfo.ic_front } alt="ic front"  style={{flex:1,width:'100%', height:'100%' }}/>
                                                                    : <ArtTrackOutlined fontSize="large" />}
                                                                <Typography variant="body2">{t('profile.icFront')}</Typography>
                                                            </Box>
                                                        </Button>
                                                        <Button
                                                            className={classes.uploadButtonContainer}
                                                            onClick={() => icBackRef.current.click()}
                                                            variant="outlined"
                                                        >
                                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                                {addressInfo.ic_back?
                                                                    <img src={addressInfo.ic_back.file_name? addressInfo.ic_back.file_name:addressInfo.ic_back} alt="ic back"  style={{flex:1,width:'100%', height:'100%' }} />
                                                                    : <AspectRatioOutlined fontSize="large" />}
                                                                <Typography variant="body2">{t('profile.icBack')}</Typography>
                                                            </Box>
                                                        </Button>
                                                    </Box>
                                                    <Typography variant="caption" color="error">{inputErrors.icFront}</Typography><br/>
                                                    <Typography variant="caption" color="error">{inputErrors.icBack}</Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item>
                                            <TextField
                                                id="country"
                                                label={t('address.country')}
                                                variant="outlined"
                                                fullWidth
                                                select
                                                value={addressInfo.country}
                                                helperText={inputErrors.country ? inputErrors.country : ''}
                                                error={inputErrors.country ? true : false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, country: target.value, state: '', city: '', address2: '' })}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect')}{t('address.country')}
                                                </option>
                                                {countryList.map((option) => (
                                                    <option key={option.code} value={option.code}>
                                                        {i18n.language === 'cn' ? option.name_cn : option.name}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="zip"
                                                label={t('address.zip')}
                                                variant="outlined"
                                                fullWidth
                                                value={addressInfo.zip}
                                                helperText={inputErrors.zip ? inputErrors.zip : ''}
                                                error={inputErrors.zip ? true : false}
                                                name ="addressZip"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {
                                                stateGroup.includes(addressInfo.country)
                                                    ?
                                                    <TextField
                                                        id="state"
                                                        label={t('address.state')}
                                                        variant="outlined"
                                                        fullWidth
                                                        select
                                                        value={addressInfo.state}
                                                        helperText={inputErrors.state ? inputErrors.state : ''}
                                                        error={inputErrors.state ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, state: target.value, city: '', address2: '' })}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    >
                                                        <option key='' value='' disabled>
                                                            {t('address.pleaseSelect')}{t('address.state')}
                                                        </option>
                                                        {stateList.map((option) => (
                                                            (option.country_code === addressInfo.country) ?
                                                                <option key={option.id} value={option.id}>
                                                                    {i18n.language === 'cn' ? option.name_cn : option.name}
                                                                </option>
                                                                : null
                                                        ))}
                                                    </TextField>
                                                    :
                                                    <TextField
                                                        id="state_text"
                                                        label={t('address.state')}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={addressInfo.state}
                                                        helperText={inputErrors.state ? inputErrors.state : ''}
                                                        error={inputErrors.state ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, state: target.value })}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                cityGroup.includes(addressInfo.state)
                                                    ?
                                                    <TextField
                                                        id="city"
                                                        label={t('address.city')}
                                                        variant="outlined"
                                                        fullWidth
                                                        select
                                                        value={addressInfo.city}
                                                        helperText={inputErrors.city ? inputErrors.city : ''}
                                                        error={inputErrors.city ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, city: target.value, address2: '' })}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    >
                                                        <option key='' value='' disabled>
                                                            {t('address.pleaseSelect')}{t('address.city')}
                                                        </option>
                                                        {cityList.map((option) => (
                                                            (option.state_id === addressInfo.state) ?
                                                                <option key={option.id} value={option.id}>
                                                                    {i18n.language === 'cn' ? option.name_cn : option.name}
                                                                </option>
                                                                : null
                                                        ))}
                                                    </TextField>
                                                    :
                                                    <TextField
                                                        id="city_text"
                                                        label={t('address.city')}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={addressInfo.city}
                                                        helperText={inputErrors.city ? inputErrors.city : ''}
                                                        error={inputErrors.city ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, city: target.value })}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                districtGroup.includes(addressInfo.city)
                                                    ?
                                                    <TextField
                                                        id="address2"
                                                        label={t('address.address2')}
                                                        variant="outlined"
                                                        fullWidth
                                                        select
                                                        value={addressInfo.address2 || ""}
                                                        helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                                                        error={inputErrors.address2 ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, address2: target.value })}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    >
                                                        <option key='' value='' disabled>
                                                            {t('address.pleaseSelect')}{t('address.address2')}
                                                        </option>
                                                        {districtList.map((option) => (
                                                            (option.city_id === addressInfo.city) ?
                                                                <option key={option.id} value={option.id}>
                                                                    {i18n.language === 'cn' ? option.name_cn : option.name}
                                                                </option>
                                                                : null
                                                        ))}
                                                    </TextField>
                                                    :
                                                    <TextField
                                                        id="address2_text"
                                                        label={t('address.address2')}
                                                        variant="outlined"
                                                        fullWidth
                                                        value={addressInfo.address2 || ""}
                                                        helperText={inputErrors.address2 ? inputErrors.address2 : ''}
                                                        error={inputErrors.address2 ? true : false}
                                                        onChange={({ target }) => setAddressInfo({ ...addressInfo, address2: target.value })}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="address"
                                                label={t('address.address')}
                                                variant="outlined"
                                                fullWidth
                                                value={addressInfo.address}
                                                helperText={inputErrors.address ? inputErrors.address : ''}
                                                error={inputErrors.address ? true : false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, address: target.value })}
                                            />
                                        </Grid>
                                    </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={form_submit} color="primary">
                                    {t('button.save')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog onClose={handleDeletePopupClose} open={popupOpen} fullWidth>
                            <DialogTitle onClose={handleDeletePopupClose}>
                                {t('address.removeTitle')}
                            </DialogTitle>
                            <DialogContent dividers>
                                {t('address.removeContent')}
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={form_submit} color="primary">
                                    {t('button.yesPlease')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog onClose={handleAddressPopupClose} open={addressReminderOpen} fullWidth>
                            <DialogTitle onClose={handleAddressPopupClose}>
                                {t('dialog.closeDialogTitle')}
                            </DialogTitle>
                            <DialogContent dividers>
                                {t('dialog.closeDialogContent')}
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus color="primary" onClick={handleDialogClose}>
                                        {t('button.yesPlease')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Grid container spacing={3} direction="column">
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="role" 
                                label={t('user.role')} 
                                variant="outlined" 
                                fullWidth 
                                select
                                value={state.role}
                                helperText={inputErrors.role?inputErrors.role:''}
                                error={inputErrors.role?true:false}
                                onChange={({ target }) => handleRoleChange({ target }) }
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                            >
                            {allRoles.map((value, key) => {
                                const displayName = _.split(value.display_name, '|');
                                return (
                                    <option key={key} value={value.name}>
                                        { i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0] }
                                    </option>
                                )
                            })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">{t('user.permissions')}</FormLabel>
                            <FormGroup>
                                <Grid container>
                                {Object.values(allPermissions).map((value, key) => (
                                    value.parent_id === "0"?
                                    <Grid item xs={12} md={6} key={value.id}>
                                    <FormControlLabel
                                        key={value.id}
                                        control={<Checkbox key={value.id} name={value.name} checked={value.isChecked} disabled={value.isDisabled} onChange={({ target }) => handlePermissionsChange({ target })} />}
                                        label={value.name}
                                    />
                                    {Object.values(allPermissions).map((value2, key2) => (
                                        parseInt(value2.parent_id) === value.id ?
                                        <Box key={value2.id} spacing={2} paddingLeft={5}>
                                            <FormControlLabel
                                                key={value2.id}
                                                control={<Checkbox key={value2.id} name={value2.name} checked={value2.isChecked} disabled={value2.isDisabled} onChange={({ target }) => handlePermissionsChange({ target })} />}
                                                label={value2.name}
                                            />
                                        </Box>
                                        : null
                                    ))}
                                    </Grid>
                                    : null
                                ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit} >{t('button.save')}</Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                        <Grid item xs={12} md={6} style={{ marginBottom: "30px" }}>
                            <Button variant="outlined" onClick={handleBindDialogOpen}><AddIcon />{t('bindSubAccount.bind')}</Button>
                        </Grid>
                        <MaterialTable
                            title=""
                            columns={[
                                { render: rowData => <div><Button onClick={({ event }) => handleUnbindPopupOpen(rowData)}><DeleteForeverIcon /></Button></div> },
                                { title: 'ID', field: 'id' },
                                { title: t('profile.username'), field: 'username', render: rowData => <Link underline='none' to={`/members/${rowData.id}`} component={RouterLink}>{rowData.username}</Link> },
                                { title: t('user.role'), field: 'user_role', render: rowData => t('role.' + rowData.user_role) },
                            ]}
                            data={bindSubAccount}
                            options={{
                                pageSize: 10,
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: t('table.labelDisplayedRows'),
                                    labelRowsSelect: t('table.rows'),
                                    firstTooltip: t('table.firstPage'),
                                    previousTooltip: t('table.previousPage'),
                                    nextTooltip: t('table.nextPage'),
                                    lastTooltip: t('table.lastPage'),
                                },
                                toolbar: {
                                    searchTooltip: t('table.search'),
                                    searchPlaceholder: t('table.search'),
                                },
                                body: {
                                    emptyDataSourceMessage: t('table.noRecord'),
                                }
                            }}
                        />
                        {/* Bind Account  */}
                        <Dialog
                            open={subAccountInfo.bindDialog}
                            onClose={handleBindDialogClose}
                            fullWidth
                        >
                            <DialogTitle onClose={handleBindDialogClose}>
                                {t('bindSubAccount.title')}
                            </DialogTitle>
                            <DialogContent>
                                {
                                    subAccountInfo.bindSuccess ?
                                        <Grid container spacing={2} justify="center" align="center">
                                            <Grid item xs={12}>
                                                <LinkIcon style={{ color: 'green' }} fontSize="large" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DialogContentText>
                                                    {t('bindSubAccount.bindSuccess')}
                                                </DialogContentText>
                                            </Grid>
                                        </Grid>
                                        :
                                        <>
                                            <DialogContentText>
                                                {t('bindSubAccount.bindUsername')}
                                            </DialogContentText>
                                            <TextField
                                                error={subAccountInfo.errorMsg.username ? true : false}
                                                helperText={subAccountInfo.errorMsg.username}
                                                fullWidth={true}
                                                variant="outlined"
                                                size="small"
                                                inputProps={{ name: 'username' }}
                                                onChange={({ target }) => setSubAccountInfo({ ...subAccountInfo, username: target.value })}
                                                value={subAccountInfo.username ?? ""}
                                                autoFocus
                                            />
                                        </>
                                }
                            </DialogContent>
                            <DialogActions>
                                {
                                    subAccountInfo.bindSuccess ?
                                        null
                                        :
                                        <Button onClick={bindAccount} autoFocus color="primary">
                                            {t('button.confirm')}
                                        </Button>
                                }
                            </DialogActions>

                        </Dialog>
                        {/* Unbind Account */}
                        <Dialog
                            open={subAccountInfo.unbindPopup}
                            onClose={handleUnbindPopupClose}
                            fullWidth
                        >
                            <DialogTitle onClose={handleUnbindPopupClose}>
                                {t('bindSubAccount.unbindTitle')}
                            </DialogTitle>
                            <DialogContent>
                                {
                                    subAccountInfo.unbindSuccess ?
                                        <Grid container spacing={2} justify="center" align="center">
                                            <Grid item xs={12}>
                                                <LinkOffIcon color="primary" fontSize="large" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DialogContentText>
                                                    {t('bindSubAccount.unbindSuccess')}
                                                </DialogContentText>
                                            </Grid>
                                        </Grid>
                                        :

                                        <DialogContentText>
                                            {t('bindSubAccount.unbindContent', { 'username': subAccountInfo.username })}
                                        </DialogContentText>

                                }
                            </DialogContent>
                            <DialogActions>
                                {
                                    subAccountInfo.unbindSuccess ?
                                        null
                                        :
                                        <Button onClick={unbindAccount} autoFocus color="primary">
                                            {t('button.confirm')}
                                        </Button>
                                }
                            </DialogActions>
                        </Dialog>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                        <MaterialTable
                            title=""
                            columns={[
                                { title: 'ID', field: 'id' },
                                { title: t('editProfitLimit.package'), field: 'title_display' },
                                { title: t('profile.username'), field: 'username', render: rowData => rowData.order.recipient.username},
                                { title: t('user.profitLimit'), field: 'profit_limit'},
                                { title: t('editProfitLimit.profitLimitTotal'), field: 'profit_limit_total'},
                                _.includes(permissions, "profit_limit_edit") ?
                                { render: rowData =><div><Button onClick={({ event }) => handleProfitLimitOpen(rowData)}><CreateIcon /></Button></div> }:{},    
                                
                            ]}
                            data={profitLimitList}
                            options={{
                                pageSize: 10,
                            }}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: t('table.labelDisplayedRows'),
                                    labelRowsSelect: t('table.rows'),
                                    firstTooltip: t('table.firstPage'),
                                    previousTooltip: t('table.previousPage'),
                                    nextTooltip: t('table.nextPage'),
                                    lastTooltip: t('table.lastPage'),
                                },
                                toolbar: {
                                    searchTooltip: t('table.search'),
                                    searchPlaceholder: t('table.search'),
                                },
                                body: {
                                    emptyDataSourceMessage: t('table.noRecord'),
                                }
                            }}
                        />
                        {/* Edit Profit Limit Account */}
                        <Dialog
                            open={ProfitLimitInfo.editdialog}
                            onClose={handleProfitLimitClose}
                            fullWidth
                        >
                            <DialogTitle onClose={handleProfitLimitClose}>
                                {t('editProfitLimit.title')}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} justify="center" align="center">
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            id="profitLimit" 
                                            label={t('editProfitLimit.profitLimit')} 
                                            variant="outlined" 
                                            fullWidth 
                                            value={ProfitLimitInfo.profit_limit}
                                            helperText={inputErrors.profit_limit?inputErrors.profit_limit:''}
                                            error={inputErrors.profit_limit?true:false}
                                            onChange={({ target }) => setProfitLimitInfo({ ...ProfitLimitInfo, profit_limit: target.value }) }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField 
                                            id="profitLimitTotal" 
                                            label={t('editProfitLimit.profitLimitTotal')} 
                                            variant="outlined" 
                                            fullWidth 
                                            value={ProfitLimitInfo.profit_limit_total}
                                            helperText={inputErrors.profit_limit_total?inputErrors.profit_limit_total:''}
                                            error={inputErrors.profit_limit_total?true:false}
                                            onChange={({ target }) => setProfitLimitInfo({ ...ProfitLimitInfo, profit_limit_total: target.value }) }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={update_profit_limit} autoFocus color="primary">
                                    {t('button.save')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                </TabPanel>
            </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));