import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { putUrl } from '@utils/ApiAction';
import { DropzoneArea } from 'material-ui-dropzone';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Button, Grid, Badge, IconButton, FormHelperText, List, ListItem, ListItemSecondaryAction, RootRef } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


export default function CompanyEditIconBanner({ id, companyInfo, setCompanyMedia, companyMedia, setLoading, addAlert }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [inputErrors, setInputErrors] = useState([]);
    const [newImages, setNewImages] = useState([]);

    useEffect(() => {
        
    }, []);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const uploadIcon = async event => {
        if(event.target.files[0]){
            setLoading(true);
            setInputErrors([]);

            let newImageBase64 = await toBase64(event.target.files[0]);

            putUrl(`admin/companies/${id}/icon`, {image: newImageBase64}).then(response => {
                setLoading(false);
                let { status, message, errors, data } = response;
                if (status === 1) {
                    addAlert('', message, 'success', '');
                    setCompanyMedia({...companyMedia, icon: data });
                } else {
                    addAlert('', message, 'error', '');
                    if (errors) {
                        setInputErrors(errors);
                    }
                }
            }).catch((error) => {
                setLoading(false);
                let msg = error + "\n" + t('error.contactSupport');
                addAlert('', msg, 'error', '');
            });
        }
    };

    const deleteBanner = (fileId) => {
        let currentArray = [...companyMedia.banners];
        let deleteIndex = currentArray.findIndex(item => item.id === fileId);
        currentArray.splice(deleteIndex, 1);
        setCompanyMedia({...companyMedia, banners: currentArray});
    };

    const updateBanners = async () => {
        let newImagesBase64 = [];
        setLoading(true);
        setInputErrors([]);

        if (_.size(newImages) > 0) {
            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }
        putUrl(`admin/companies/${id}/banners`, { images: newImagesBase64, ids: _.map(companyMedia.banners, 'id') }).then(response => {
            setLoading(false);
            let { status, message, errors, data } = response;
            if (status === 1) {
                addAlert('', message, 'success', '');
                setCompanyMedia({ ...companyMedia, banners: data });
            } else {
                addAlert('', message, 'error', '');
                if (errors) {
                    setInputErrors(errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        // const result = Array.from(list);
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            companyMedia.banners,
            result.source.index,
            result.destination.index
        );
        setCompanyMedia({...companyMedia, banners: items});
    }
    const getItemStyle = (isDragging, draggableStyle) => ({
        // styles we need to apply on draggables
        ...draggableStyle,
    
        ...(isDragging && {
            background: "rgb(235,235,235)"
        })
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightblue' : '',
    });

    return (
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <Badge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<SmallAvatar alt="icon"><input accept="image/*" className={classes.inputFile} id="icon-button-file" type="file" onChange={uploadIcon} /><label htmlFor="icon-button-file"><IconButton component="span"><EditIcon style={{ fontSize: "small" }} /></IconButton></label></SmallAvatar>}
                >
                    <Avatar className={classes.large} alt="icon" src={_.size(companyMedia) && _.size(companyMedia.icon) ? companyMedia.icon.file_name : ''} />
                </Badge>
                <Typography variant="h6">{companyInfo.shopName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('company.banners')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <RootRef rootRef={provided.innerRef}>
                                <List dense style={getListStyle(snapshot.isDraggingOver)}>
                                    {_.size(companyMedia) ?
                                        _.map(companyMedia.banners, (row, index) => {
                                            return ([
                                                <Draggable key={`listitem-${row.id}`} draggableId={`listitem-${row.id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <ListItem
                                                            divider
                                                            ContainerComponent="li"
                                                            ContainerProps={{ ref: provided.innerRef }}
                                                            innerRef={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            <img className={classes.bannerListImg} src={row.file_name} alt="companybanner" />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" aria-label="delete" onClick={() => deleteBanner(row.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )}
                                                </Draggable>
                                            ])
                                        })
                                        : null}
                                    {provided.placeholder}
                                </List>
                            </RootRef>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <Typography style={{margin: 5}}>{t('general.recommendedSize', {H: '1900', W: '250'})}</Typography>
                <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText={t('company.dragDropImageHere')}
                    onChange={(files) => setNewImages(files)}
                    filesLimit={10 - _.size(companyMedia.banners)}
                    style={{ outline: 'none'}}
                    dropzoneClass={classes.dropzone}
                    maxFileSize={2000000}
                />
                <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10 - _.size(companyMedia.banners)}) + t('general.fileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                {inputErrors ?
                    <FormHelperText style={{ color: "red" }}>{inputErrors}</FormHelperText>
                    : null
                }
            </Grid>

            <Grid item xs={12} md={12}>
                <Button variant="contained" size="medium" color="primary" onClick={updateBanners}>{t('button.save')}</Button>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none'
    },
    bannerListImg: {
        width: "200px",
        maxHeight: "120px"
    },
    dropzone: {
        outline: "none",
    }
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);