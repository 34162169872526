import { QRCodeSVG } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from "react-to-print";

import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { MEMBER_URL } from '@configs/Config';

// Dialog
import { Dialog, IconButton } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function CompanyEditQR({ id, shopName, shopSlug, addAlert }) {

    const { t, i18n } = useTranslation();
    const [qrDialog, setQrDialog] = useState(false);
    const qrUrl = `${MEMBER_URL}/spend?m=${btoa(id)}`;

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({ contentRef });

    const copyToClipboard = () => {
        navigator.clipboard.writeText(qrUrl)
            .then(() => {
                addAlert('', t('seller.copySuccess'), 'success', '');
            })
            .catch(err => {
                addAlert('', err?.message || t('seller.copyFailed'), 'error', '');
            });
    };

    const ComponentToPrint = ({ innerRef }) => {
        let shopNameArray = shopName.split("|");
        let displayName = i18n.language === 'cn' ? (shopNameArray[1] ? shopNameArray[1] : shopNameArray[0]) : shopNameArray[0];
        return (
            <div style={{ height: '100%', width: "100%", backgroundColor: 'white', paddingTop: '10%', backgroundSize: '100%' }} ref={innerRef}>
                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <img alt='logo' src="../logo512.png" style={{ width: "25%" }} />
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", marginTop: 40 }}>
                    <QRCodeSVG
                        size='300'
                        style={{ backgroundColor: 'white', padding: 30, border: 'none', borderRadius: '18px', boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.15) inset, 6px 6px 6px 0px rgba(0, 0, 0, 0.11)' }}
                        value={qrUrl}
                    />
                </Box>
                <Box style={{ width: "100%", textAlign: "center", paddingTop: 40 }}>
                    <Typography style={{ fontWeight: "bold", fontSize: '40px' }} align="center">{displayName}</Typography>
                    <Typography style={{ fontWeight: "bold", fontSize: '35px' }} align="center">{"(@" + shopSlug + ")"}</Typography>
                </Box>
            </div>
        )
    }

    return (
        <Box>
            <Button variant="contained" style={{ marginLeft: 10 }} size='small' onClick={() => setQrDialog(!qrDialog)}>{t('seller.qrCode')}</Button>
            <Dialog maxWidth="sm" open={qrDialog} onClose={() => setQrDialog(!qrDialog)} fullWidth>
                <DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>{t(`seller.qrCode`)}</div>
                        <IconButton onClick={() => setQrDialog(!qrDialog)}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <QRCodeSVG
                        size='180'
                        value={qrUrl}
                    />
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 25 }}>
                    <Button variant="contained" style={{ marginLeft: 10 }} size='small' onClick={handlePrint}>{t('button.print')}</Button>
                    <Button variant="outlined" style={{ marginLeft: 10 }} size='small' onClick={copyToClipboard}>{t('button.copyToClipboard')}</Button>
                    <div style={{ display: "none" }}>
                        <ComponentToPrint innerRef={contentRef} />
                    </div>
                </DialogActions>
            </Dialog>
        </Box>
    );
}