import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { getUrl } from '@utils/ApiAction';
import { useQuery } from '@utils/Tools';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Paper, LinearProgress, Tooltip, Toolbar, IconButton, Icon, Input, InputAdornment, FormControl } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

export default function Wallets() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    
    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/wallets${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;
        setTableLoading(true);
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order };
        getUrl('admin/wallets_list', params).then(result => {
            setTableLoading(false);
            if(isMountedRef.current) {
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            setTableLoading(false);
            if(isMountedRef.current) {
                setListing([]);
                setTotalCount(0)
            }
        });
        return () => { isMountedRef.current = false };
    }, [page, rowsPerPage, querySearch, orderBy, order]);
    
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('title.wallets')}</Typography>
                    <Link underline='none' color="inherit" to="/wallets/add" component={RouterLink}>
                        <Button size="medium" variant="outlined" startIcon={<AddIcon />}>{t('button.new')}</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>{ t('wallet.action') }</TableCell>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.name')} cellId={'name'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.source')} cellId={'source'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.code')} cellId={'code'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.decimal')} cellId={'decimal'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.allowNegative')} cellId={'allow_negative'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.isCash')} cellId={'is_cash'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.allowMerchant')} cellId={'allow_merchant'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.cashRate')} cellId={'cash_rate'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('wallet.createdAt')} cellId={'created_at'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {                                    
                                    return(
                                        <TableRow key={row.id}>
                                            <TableCell><Link underline='none' to={`/wallets/${row.id}`} component={RouterLink}><EditIcon /></Link></TableCell>
                                            <TableCell>{ row.name }</TableCell>
                                            <TableCell>{ row.source }</TableCell>
                                            <TableCell>{ row.code }</TableCell>
                                            <TableCell>{ row.decimal }</TableCell>
                                            <TableCell>{ row.allow_negative?t('wallet.yes'):t('wallet.no') }</TableCell>
                                            <TableCell>{ row.is_cash?t('wallet.yes'):t('wallet.no') }</TableCell>
                                            <TableCell>{ row.allow_merchant?t('wallet.yes'):t('wallet.no') }</TableCell>
                                            <TableCell>{ row.cash_rate?row.cash_rate:'-' }</TableCell>
                                            <TableCell>{ row.updated_at }</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={9} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));