import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, IconButton, Typography, Breadcrumbs, Link, Grid, Paper, Button, TextField, Switch, Checkbox, FormControl, FormLabel, FormControlLabel, FormGroup, AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { getUrl, postUrl, putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import PropTypes from 'prop-types';
// Icon
import ContactsIcon from '@material-ui/icons/Contacts';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PageviewIcon from '@material-ui/icons/Pageview';
import LockIcon from '@material-ui/icons/Lock';

// Dialog
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Dialog
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function areEqualShallow(a, b) {
    for(var key in a) {
        if(a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}

export default function MerchantEdit() {
    const classes = useStyles();
    const theme = useTheme();

    let history = useHistory();

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [reminderPopupOpen, setReminderPopupOpen] = useState(false);
    const [addressReminderOpen, setAddressReminderOpen] = useState(false);
    const { permissions, role, isAdmin } = useSelector(state => state.general);

    const [passwordResult, setPasswordResult] = useState("");
    const [allRoles, setAllRoles] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [rolesPermissions, setRolesPermissions] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [addressInfo, setAddressInfo] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateGroup, setStateGroup] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [cityGroup, setCityGroup] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [districtGroup, setDistrictGroup] = useState([]);
    const [emptyAddress] = useState({
        id: 0,
        default: false,
        delete: false,
        country: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        address2: '',
    });
    const [state, setState] = useState({
        companyId: '',
        companyName: '',
        name: '',
        username: '',
        email: '',
        mobile: '',
        role: '',
    });
    const [oldDataBasic, setOldDataBasic] = useState({
        companyId: '',
        companyName: '',
        name: '',
        username: '',
        email: '',
        mobile: '',
        role: '',
    });
    const [oldDataPermissions, setOldDataPermissions] = useState([]);
    const [oldAddressInfo, setOldAddressInfo] = useState([]);
    const [passwordInfo, setPasswordInfo] = useState({
        password: '',
        passwordConfirmation: '',
    });

    let { id } = useParams();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handlePermissionsChange = (event) => {
        setAllPermissions({ ...allPermissions, [event.target.name]: {
            ...allPermissions[event.target.name],
            isChecked: event.target.checked,
        }});
    };
    const handleRoleChange = (event) => {
        let newState = allPermissions;
        Object.values(rolesPermissions[state.role]).map((value, key) => (
            newState[value] = {
                ...allPermissions[value],
                isChecked: false,
                isDisabled: false,
            }
        ));
        Object.values(rolesPermissions[event.target.value]).map((value, key) => (
            newState[value] = {
                ...allPermissions[value],
                isChecked: true,
                isDisabled: true,
            }
        ));
        setState({ ...state, role: event.target.value });
        setAllPermissions(newState);
    };
    const handleDialogOpen = (event) => {
        setDialogOpen(true);
        let address = event;
        address.delete = false;
        setAddressInfo(address);
        setOldAddressInfo(address);
        setInputErrors("");
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setAddressReminderOpen(false);
    };
    const dialogCloseReminder = () => {
        if(!areEqualShallow(addressInfo, oldAddressInfo)){
            setAddressReminderOpen(true);
        }else{
            setDialogOpen(false);
        }
    };
    const handleDeletePopupOpen = (event) => {
        let address = event;
        address.delete = true;
        setAddressInfo(address);
        setPopupOpen(true);
    };
    const handleDeletePopupClose = () => {
        setAddressInfo({ ...addressInfo, delete: false });
        setPopupOpen(false);
    };
    const handleReminderPopupOpen = (event) => {
        let pop = false;
        if(!areEqualShallow(state, oldDataBasic)){
            pop = true;
        }else if(!areEqualShallow(allPermissions, oldDataPermissions)){
            pop = true;
        }
        if(pop){
            setReminderPopupOpen(true);
        }else{
            history.push("/sellers/"+[state.companyId]);
        }
    }
    const handleReminderPopupClose = () => {
        setReminderPopupOpen(false);
    };
    const handleAddressPopupClose = (event) => {
        setAddressReminderOpen(false);
    };

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/merchants/${id}`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setState({
                    companyId: response.data.merchant_company_id?response.data.merchant_company_id:'-',
                    companyName: response.data.merchant_company_name?response.data.merchant_company_name:'-',
                    name: response.data.name,
                    username: response.data.username?response.data.username:'',
                    email: response.data.email?response.data.email:'',
                    mobile: response.data.mobile?response.data.mobile:'',
                    role: response.data.user_role,
                });
                setAllRoles(response.all_roles);
                setAllPermissions(response.all_permissions);
                setRolesPermissions(response.roles_permission);
                setAddresses(response.addresses);
                setOldDataPermissions(response.all_permissions);
                setOldDataBasic({
                    companyId: response.data.merchant_company_id?response.data.merchant_company_id:'-',
                    companyName: response.data.merchant_company_name?response.data.merchant_company_name:'-',
                    name: response.data.name,
                    username: response.data.username?response.data.username:'',
                    email: response.data.email?response.data.email:'',
                    mobile: response.data.mobile?response.data.mobile:'',
                    role: response.data.user_role,
                });
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        getUrl(`admin/address`).then(response => {
            if(response.status === 1 && isMountedRef.current) {
                setCountryList(response.country_list);
                setStateList(response.state_list);
                setStateGroup(response.state_group);
                setCityList(response.city_list);
                setCityGroup(response.city_group);
                setDistrictList(response.district_list);
                setDistrictGroup(response.district_group);
            }else{
                let msg = response.data;
                addAlert('', msg, 'error', '');
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert]);

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        let updateData = '';
        if(tabValue === 0){
            updateData = {
                update_tab: 'basic',
                name: state.name,
                username: state.username,
                // mobile: state.mobile,
                email: state.email,
            };
        }else if(tabValue === 1){
            updateData = {
                update_tab: 'addresses',
                id: addressInfo.id,
                default: addressInfo.default,
                delete: addressInfo.delete,
                country: addressInfo.country,
                zip: addressInfo.zip,
                state: addressInfo.state,
                city: addressInfo.city,
                address2: addressInfo.address2,
                address: addressInfo.address,
            };
        }else if(tabValue === 3){
            updateData = {
                update_tab: 'permissions',
                role: state.role,
                permissions: allPermissions,
            };
        }
        putUrl(`admin/merchants/${id}`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
                if(response.addresses){
                    setAddresses(response.addresses);
                }
                if(tabValue === 0){
                    setOldDataBasic(state);
                }else if(tabValue === 1){
                    setOldAddressInfo(addressInfo);
                }else if(tabValue === 3){
                    setOldDataPermissions(allPermissions);
                }
                setDialogOpen(false);
                setPopupOpen(false);
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    };

    const sendResetPasswordLink = () => {
        setLoading(true);
        setPasswordResult('');
        postUrl(`admin/merchants/${id}/reset-password`)
        .then(response => {
            setLoading(false);
            setPasswordResult(response.status ? '':response.message);
            if(response.status) {
                let msg = t('user.resetPasswordSend');
                addAlert('', msg, 'success', '');
            }
        }).catch(error => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    const update_password = () => {
        setLoading(true);
        setInputErrors("");
        let updateData = {
            password: passwordInfo.password,
            password_confirmation: passwordInfo.passwordConfirmation,
        };
        putUrl(`admin/merchants/${id}/password`, updateData).then(response => {
            setLoading(false);
            if(response.status === 1) {
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'success', '');
                }else{
                    let msg = t('snackbarMsg.updateSuccess');
                    addAlert('', msg, 'success', '');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.updateError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            let msg = error+"\n"+t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('merchant.editMerchant')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/merchants`} component={RouterLink}>{t('merchant.merchants')}</Link>
                        <Typography style={{ color: 'black' }}>{t('merchant.editMerchant')}</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Divider /> */}
            </Grid>
            <Grid item xs={12}>
                {
                    state.companyId && _.includes(permissions, "company_edit")
                    ?
                    <Grid item xs={12} md={6}>
                        <Button size="small" startIcon={<PageviewIcon />} onClick={handleReminderPopupOpen}>{t('button.viewSeller')}</Button>
                    </Grid>
                    :
                    null
                }
                <Dialog onClose={handleReminderPopupClose} open={reminderPopupOpen} fullWidth>
                    <DialogTitle onClose={handleReminderPopupClose}>
                        {t('merchant.viewCompanyPageTitle')}
                    </DialogTitle>
                    <DialogContent dividers>
                        {t('merchant.viewCompanyPageContent')}
                    </DialogContent>
                    <DialogActions>
                        <Link underline='none' color="inherit" to={"/companies/"+[state.companyId]} component={RouterLink}>
                            <Button autoFocus color="primary">
                                {t('button.yesPlease')}
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
                <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color: theme.palette.primary.main,WebkitBoxShadow:'none'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered indicatorColor="primary">
                        <Tab icon={<ContactsIcon />} label={t('user.basic')} {...a11yProps(0)} />
                        <Tab icon={<HomeWorkIcon />} label={t('user.address')} {...a11yProps(1)} />
                        {
                            isAdmin &&
                            <Tab icon={<LockIcon />} label={t('user.password')} {...a11yProps(2)} />
                        }
                        {
                            isAdmin &&
                            <Tab icon={<PlaylistAddCheckIcon />} label={t('user.permissions')} {...a11yProps(3)} />
                        }
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <TabPanel value={tabValue} index={0}>
                        <Grid container spacing={3} >
                            {
                            isAdmin &&
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="company_name" 
                                    label={t('seller.shopName')} 
                                    variant="outlined" 
                                    fullWidth
                                    value={state.companyName}
                                    helperText={inputErrors.companyName?inputErrors.companyName:''}
                                    error={inputErrors.companyName?true:false}
                                    onChange={({ target }) => setState({ ...state, companyName: target.value }) }
                                    InputProps={{ readOnly: true }}
                                />
                            </Grid>
                            }
                            {/* <Grid item xs={12} md={6}>
                                <TextField 
                                    id="email" 
                                    label={t('profile.email')} 
                                    variant="outlined" 
                                    fullWidth
                                    value={state.email}
                                    helperText={inputErrors.email?inputErrors.email:''}
                                    error={inputErrors.email?true:false}
                                    onChange={({ target }) => setState({ ...state, email: target.value }) }
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="name" 
                                    label={t('profile.name')} 
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.name}
                                    helperText={inputErrors.name?inputErrors.name:''}
                                    error={inputErrors.name?true:false}
                                    onChange={({ target }) => setState({ ...state, name: target.value }) }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="username" 
                                    label={t('profile.username')} 
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.username}
                                    helperText={inputErrors.username?inputErrors.username:''}
                                    error={inputErrors.username?true:false}
                                    onChange={({ target }) => setState({ ...state, username: target.value }) }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="email"
                                    label={t('profile.email')}
                                    variant="outlined"
                                    fullWidth
                                    value={state.email}
                                    helperText={inputErrors.email ? inputErrors.email : ''}
                                    error={inputErrors.email ? true : false}
                                    onChange={({ target }) => setState({ ...state, email: target.value })}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={6}>
                                <TextField 
                                    id="mobile" 
                                    label={t('profile.mobile')} 
                                    variant="outlined" 
                                    fullWidth 
                                    value={state.mobile}
                                    helperText={inputErrors.mobile?inputErrors.mobile:''}
                                    error={inputErrors.mobile?true:false}
                                    onChange={({ target }) => setState({ ...state, mobile: target.value }) }
                                />
                            </Grid> */}
                            <Grid item xs={12} md={6}>
                                <Button variant="contained"  size="medium" color="primary" onClick={form_submit} >{t('button.save')}</Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid container spacing={3} direction="column">
                            {addresses.map((value, key) => (
                                <Grid item xs={12} md={6} key={value.id}>
                                    <Button disabled>
                                        {value.default?<StarIcon />:<StarBorderIcon />}
                                    </Button>
                                    <Button onClick={({ event }) => handleDialogOpen( value ) }>
                                        <EditIcon />
                                    </Button>
                                    <Button onClick={({ event }) => handleDeletePopupOpen( value ) }>
                                        <DeleteForeverIcon />
                                    </Button>
                                    <TextField 
                                        id="addresses[]" 
                                        label={t('address.address')} 
                                        variant="outlined" 
                                        fullWidth
                                        multiline
                                        rowsMax={4}
                                        value={value.full_address}
                                        InputProps={{ readOnly: true }}
                                    /> 
                                    </Grid>
                            ))}
                            <Grid item xs={12} md={6}>
                                <Button onClick={({ event }) => handleDialogOpen( emptyAddress ) }><AddIcon /></Button> 
                            </Grid>
                            <Dialog onClose={dialogCloseReminder} open={dialogOpen} fullWidth>
                                <DialogTitle id={addressInfo.id} onClose={dialogCloseReminder}>
                                    {
                                        addressInfo.id
                                        ?
                                        t('address.editAddress')
                                        :
                                        t('address.addNewAddress')
                                    }
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={addressInfo.default}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, default: target.checked }) }
                                                    name="addressed_default"
                                                    color="primary"
                                                    />
                                                }
                                                labelPlacement="start"
                                                label={t('address.default')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                id="country" 
                                                label={t('address.country')} 
                                                variant="outlined" 
                                                fullWidth 
                                                select
                                                value={addressInfo.country}
                                                helperText={inputErrors.country?inputErrors.country:''}
                                                error={inputErrors.country?true:false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, country: target.value, state: '', city: '', address2: '' }) }
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            >
                                            <option key='' value='' disabled>
                                                {t('address.pleaseSelect')}{t('address.country')}
                                            </option>
                                            {countryList.map((option) => (
                                                <option key={option.code} value={option.code}>
                                                    {i18n.language === 'cn' ?option.name_cn:option.name }
                                                </option>
                                            ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                id="zip" 
                                                label={t('address.zip')} 
                                                variant="outlined" 
                                                fullWidth 
                                                value={addressInfo.zip}
                                                helperText={inputErrors.zip?inputErrors.zip:''}
                                                error={inputErrors.zip?true:false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, zip: target.value }) }
                                            />
                                        </Grid>
                                        <Grid item>
                                            {
                                                stateGroup.includes(addressInfo.country)
                                                ?
                                                <TextField 
                                                    id="state" 
                                                    label={t('address.state')} 
                                                    variant="outlined" 
                                                    fullWidth 
                                                    select
                                                    value={addressInfo.state}
                                                    helperText={inputErrors.state?inputErrors.state:''}
                                                    error={inputErrors.state?true:false}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, state: target.value, city: '', address2: '' }) }
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect')}{t('address.state')}
                                                </option>
                                                {stateList.map((option) => (
                                                    (option.country_code === addressInfo.country)?
                                                    <option key={option.id} value={option.id}>
                                                        {i18n.language === 'cn' ?option.name_cn:option.name }
                                                    </option>
                                                    : null
                                                ))}
                                                </TextField>
                                                :
                                                <TextField 
                                                    id="state_text" 
                                                    label={t('address.state')} 
                                                    variant="outlined" 
                                                    fullWidth
                                                    value={addressInfo.state}
                                                    helperText={inputErrors.state?inputErrors.state:''}
                                                    error={inputErrors.state?true:false}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, state: target.value }) }
                                                />
                                            }                    
                                        </Grid>
                                        <Grid item>
                                            {
                                                cityGroup.includes(addressInfo.state)
                                                ?
                                                <TextField 
                                                    id="city" 
                                                    label={t('address.city')} 
                                                    variant="outlined" 
                                                    fullWidth 
                                                    select
                                                    value={addressInfo.city}
                                                    helperText={inputErrors.city?inputErrors.city:''}
                                                    error={inputErrors.city?true:false}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, city: target.value, address2: '' }) }
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect')}{t('address.city')}
                                                </option>
                                                {cityList.map((option) => (
                                                    (option.state_id === addressInfo.state)?
                                                    <option key={option.id} value={option.id}>
                                                        {i18n.language === 'cn' ?option.name_cn:option.name }
                                                    </option>
                                                    : null
                                                ))}
                                                </TextField>
                                                :
                                                <TextField 
                                                    id="city_text" 
                                                    label={t('address.city')}
                                                    variant="outlined" 
                                                    fullWidth 
                                                    value={addressInfo.city}
                                                    helperText={inputErrors.city?inputErrors.city:''}
                                                    error={inputErrors.city?true:false}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, city: target.value }) }
                                                />
                                            }
                                        </Grid>
                                        <Grid item>
                                            {
                                                districtGroup.includes(addressInfo.city)
                                                ?
                                                <TextField 
                                                    id="address2" 
                                                    label={t('address.address2')} 
                                                    variant="outlined" 
                                                    fullWidth 
                                                    select
                                                    value={addressInfo.address2}
                                                    helperText={inputErrors.address2?inputErrors.address2:''}
                                                    error={inputErrors.address2?true:false}
                                                    onChange={({ target }) => setAddressInfo({ ...addressInfo, address2: target.value }) }
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                >
                                                <option key='' value='' disabled>
                                                    {t('address.pleaseSelect')}{t('address.address2')}
                                                </option>
                                                {districtList.map((option) => (
                                                    (option.city_id === addressInfo.city)?
                                                    <option key={option.id} value={option.id}>
                                                        {i18n.language === 'cn' ?option.name_cn:option.name }
                                                    </option>
                                                    : null
                                                ))}
                                                </TextField>
                                                :
                                                <TextField 
                                                id="address2_text" 
                                                label={t('address.address2')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={addressInfo.address2}
                                                helperText={inputErrors.address2?inputErrors.address2:''}
                                                error={inputErrors.address2?true:false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, address2: target.value }) }
                                            />
                                            }
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                id="address" 
                                                label={t('address.address')}
                                                variant="outlined" 
                                                fullWidth 
                                                value={addressInfo.address}
                                                helperText={inputErrors.address?inputErrors.address:''}
                                                error={inputErrors.address?true:false}
                                                onChange={({ target }) => setAddressInfo({ ...addressInfo, address: target.value }) }
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={form_submit} color="primary">
                                        {t('button.save')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog onClose={handleDeletePopupClose} open={popupOpen} fullWidth>
                                <DialogTitle onClose={handleDeletePopupClose}>
                                    {t('address.removeTitle')}
                                </DialogTitle>
                                <DialogContent dividers>
                                    {t('address.removeContent')}
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={form_submit} color="primary">
                                        {t('button.yesPlease')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog onClose={handleAddressPopupClose} open={addressReminderOpen} fullWidth>
                                <DialogTitle onClose={handleAddressPopupClose}>
                                    {t('dialog.closeDialogTitle')}
                                </DialogTitle>
                                <DialogContent dividers>
                                    {t('dialog.closeDialogContent')}
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus color="primary" onClick={handleDialogClose}>
                                            {t('button.yesPlease')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </TabPanel>
                    {
                        isAdmin &&
                            <TabPanel value={tabValue} index={2}>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <Button variant="contained" onClick={ sendResetPasswordLink }>
                                        <Typography variant="button">{ t('user.sendResetPasswordLink') }</Typography>
                                    </Button>
                                    <Typography variant="body2" color="textPrimary" style={{ marginTop: 10 }}>{ passwordResult }</Typography>
                                </Box>
                                {
                                    _.includes(permissions, "change_user_password")
                                    ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="password" 
                                                label={t('user.password')} 
                                                variant="outlined" 
                                                fullWidth
                                                value={passwordInfo.password}
                                                helperText={inputErrors.password?inputErrors.password:''}
                                                error={inputErrors.password?true:false}
                                                onChange={(event) => setPasswordInfo({...passwordInfo, password: event.target.value})}
                                                type="password"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField 
                                                id="passwordConfirmation" 
                                                label={t('user.passwordConfirmation')} 
                                                variant="outlined" 
                                                fullWidth
                                                value={passwordInfo.passwordConfirmation}
                                                helperText={inputErrors.password_confirmation?inputErrors.password_confirmation:''}
                                                error={inputErrors.password_confirmation?true:false}
                                                onChange={(event) => setPasswordInfo({...passwordInfo, passwordConfirmation: event.target.value})}
                                                type="password"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button variant="contained" size="medium" color="primary" onClick={update_password}>{t('button.update')}</Button> 
                                        </Grid>
                                    </Grid>
                                    : null
                                }
                            </TabPanel>
                    }
                    {
                        isAdmin &&
                            <TabPanel value={tabValue} index={3}>
                            <Grid container spacing={3} direction="column">
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        id="role" 
                                        label={t('user.role')} 
                                        variant="outlined" 
                                        fullWidth 
                                        select
                                        value={state.role}
                                        helperText={inputErrors.role?inputErrors.role:''}
                                        error={inputErrors.role?true:false}
                                        onChange={({ target }) => handleRoleChange({ target }) }
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    >
                                    {allRoles.map((value, key) => {
                                        const displayName = _.split(value.display_name, '|');
                                        return (
                                            <option key={key} value={value.name}>
                                                { i18n.language === 'cn' ? _.size(displayName) > 0 ? displayName[1] : displayName[0] : displayName[0] }
                                            </option>
                                        )
                                    })}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">{t('user.permissions')}</FormLabel>
                                    <FormGroup>
                                        <Grid container>
                                        {Object.values(allPermissions).map((value, key) => (
                                            value.parent_id === "0"?
                                            <Grid item xs={12} md={6} key={value.id}>
                                            <FormControlLabel
                                                key={value.id}
                                                control={<Checkbox key={value.id} name={value.name} checked={value.isChecked} disabled={value.isDisabled} onChange={({ target }) => handlePermissionsChange({ target })} />}
                                                label={value.name}
                                            />
                                            {Object.values(allPermissions).map((value2, key2) => (
                                                parseInt(value2.parent_id) === value.id ?
                                                <Box key={value2.id} spacing={2} paddingLeft={5}>
                                                    <FormControlLabel
                                                        key={value2.id}
                                                        control={<Checkbox key={value2.id} name={value2.name} checked={value2.isChecked} disabled={value2.isDisabled} onChange={({ target }) => handlePermissionsChange({ target })} />}
                                                        label={value2.name}
                                                    />
                                                </Box>
                                                : null
                                            ))}
                                            </Grid>
                                            : null
                                        ))}
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" onClick={form_submit} >{t('button.save')}</Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));