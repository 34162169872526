import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Divider, Breadcrumbs, Link, Grid, Paper, Button, TextField, FormControlLabel, Switch, Checkbox, FormControl, FormLabel, FormGroup } from '@material-ui/core';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';

export default function WalletAdd() {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({
        source: '',
        code: '',
        name: '',
        decimal: '',
        allowNegative: false,
        isCash: false,
        allowMerchant: false,
        cashRate: '',
        display_setting: {showMember: true, showMerchant: true, showDashboard: false},
    });

    useEffect(() => {
        // eslint-disable-next-line
    }, [addAlert])

    const form_submit = () => {
        setLoading(true);
        setInputErrors("");
        const updateData = {
            source : state.source,
            code : state.code,
            name: state.name,
            decimal: state.decimal,
            allow_negative: state.allowNegative?1:0,
            is_cash: state.isCash?1:0,
            allow_merchant: state.allowMerchant?1:0,
            // cash_rate: state.isCash?'':state.cashRate,
            cash_rate: state.cashRate,
            display_setting: state.display_setting
        }
        postUrl(`admin/wallets/add`, updateData).then(response => {
            setLoading(false);
            if(response.status) {
                setState({
                    source: '',
                    code: '',
                    name: '',
                    decimal: '',
                    allowNegative: false,
                    isCash: false,
                    allowMerchant: false,
                    cashRate: '',
                    display_setting: {showMember: true, showMerchant: true, showDashboard: false},
                });
                if(response.data.id){
                    addAlert( `${t('snackbarMsg.addSuccess')} ID: ${response.data.id}`, '', 'success');
                }else{
                    addAlert( `${t('snackbarMsg.addSuccess')}`, '', 'success');
                }
            }else{
                if(response.data){
                    let msg = response.data;
                    addAlert('', msg, 'error', '');
                }else{
                    let msg = t('snackbarMsg.addError');
                    addAlert('', msg, 'error', '');
                }
                if(response.errors){
                    setInputErrors(response.errors);
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('snackbarMsg.addError'), error+"\n"+t('error.contactSupport'), 'error');
        });
    };

    return(
        <Grid container spacing={3}> 
            <Grid item xs={12} >
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('wallet.addWallet')}</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard" component={RouterLink}>{t('title.dashboard')}</Link>
                        <Link to={`/wallets`} component={RouterLink}>{t('wallet.wallets')}</Link>
                        <Typography style={{ color: 'black' }}>{t('wallet.addWallet')}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper} style={{...theme.box1,...theme.mt30}}>
                    <Grid container spacing={3} style={theme.p20}>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="source" 
                                label={t('wallet.source')}
                                variant="outlined" 
                                fullWidth 
                                value={state.source}
                                helperText={inputErrors.source ? inputErrors.source :''}
                                error={inputErrors.source ? true : false}
                                onChange={({ target }) => setState({ ...state, source: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="code" 
                                label={t('wallet.code')}
                                variant="outlined" 
                                fullWidth 
                                value={state.code}
                                helperText={inputErrors.code ? inputErrors.code :''}
                                error={inputErrors.code ? true : false}
                                onChange={({ target }) => setState({ ...state, code: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="name" 
                                label={t('wallet.name')}
                                variant="outlined" 
                                fullWidth 
                                value={state.name}
                                helperText={inputErrors.name ? inputErrors.name :''}
                                error={inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField 
                                id="decimal" 
                                label={t('wallet.decimal')}
                                variant="outlined" 
                                fullWidth 
                                value={state.decimal}
                                helperText={inputErrors.decimal ? inputErrors.decimal :''}
                                error={inputErrors.decimal ? true : false}
                                onChange={({ target }) => setState({ ...state, decimal: target.value }) }
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.allowNegative ? true : false}
                                                onChange={({ target }) => setState({ ...state, allowNegative: target.checked })}
                                                name="allowNegative"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t('wallet.allowNegative')}
                                        // helperText={inputErrors.allow_negative ? inputErrors.allow_negative :''}
                                        // error={inputErrors.allow_negative ? true : false}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                        id="cashRate" 
                                        label={t('wallet.cashRate')}
                                        variant="outlined" 
                                        fullWidth 
                                        value={state.cashRate}
                                        helperText={inputErrors.cash_rate ? inputErrors.cash_rate :''}
                                        error={inputErrors.cash_rate ? true : false}
                                        onChange={({ target }) => setState({ ...state, cashRate: target.value }) }
                                        type="number"
                                    />
                                    <Typography variant="body2" style={{color: "#808080"}}>{t('wallet.cashRateNote')}</Typography>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.allowMerchant ? true : false}
                                                onChange={({ target }) => setState({ ...state, allowMerchant: target.checked })}
                                                name="allowMerchant"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t('wallet.allowMerchant')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('wallet.displayTo')}</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={state.display_setting.showMember} onChange={({target}) => setState({ ...state, display_setting: {...state.display_setting, [target.name]: target.checked }})} name="showMember" />}
                                        label={t('wallet.member')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={state.display_setting.showMerchant} onChange={({target}) => setState({ ...state, display_setting: {...state.display_setting, [target.name]: target.checked }})} name="showMerchant" />}
                                        label={t('wallet.seller')}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={state.display_setting.showDashboard} onChange={({target}) => setState({ ...state, display_setting: {...state.display_setting, [target.name]: target.checked }})} name="showDashboard" />}
                                        label={t('wallet.dashboard')}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={form_submit}>{t('button.add')}</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({    
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));