import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector} from 'react-redux';

import _ from 'lodash';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { currencyFormat, useQuery } from '@utils/Tools';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, IconButton, Icon, useTheme, Box, AppBar, Tab, Tabs } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BarChartIcon from '@material-ui/icons/BarChart';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import * as XLSX from 'xlsx';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };

    const exportFile = () => {
        let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
        XLSX.writeFile(wb, `ReportWalletBalance${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
    }

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}

export default function ReportWalletBalance() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const { permissions } = useSelector(state => state.general);
    const [tabValue, setTabValue] = useState(_.includes(permissions, "report_seller_wallet_balance")?0:1);
    const [wallets, setWallets] = useState([]);

    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue);
    };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const setUrl = (paging, rpp, sv) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/wallet-balance${searchString !== '' ? '?'+searchString : ''}`);
    }

    useEffect(() => {
        isMountedRef.current = true;

        getUrl('admin/wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };

    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        let type = '';
        if(tabValue === 0){
            type = 'merchants';
        }else if(tabValue === 1){
            type = 'members';
        }
        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'type': type };
        setTableLoading(true);
        getUrl(`admin/wallet_balance`, params).then(result => {
            setTableLoading(false);
            if(isMountedRef.current && result.status===1) {
                setListing(result.data.data);
                setTotalCount(result.data.total);
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, tabValue]);
    
    return(
        <Grid container spacing={3}> 
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.walletBalance.title')}</Typography>
                    {/* <Tooltip title={t('title.reports')}><Link underline='none' to="/reports" component={RouterLink}><IconButton color="primary"><BarChartIcon /></IconButton></Link></Tooltip> */}
                </Grid>
            </Grid>
            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color: theme.palette.primary.main,WebkitBoxShadow:'none'}} >
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="on" >
                    {
                        _.includes(permissions, "report_seller_wallet_balance") ?
                            (<Tab icon={<StorefrontIcon />} label={t('reports.walletBalance.company')} {...a11yProps(0)} value={0} />)
                            : ("")
                    }
                    {
                        _.includes(permissions, "report_member_wallet_balance") ?
                            (<Tab icon={<PersonIcon />} label={t('reports.walletBalance.user')} {...a11yProps(1)} value={1} />)
                            : ("")
                    }
                </Tabs>
            </AppBar>
            <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                <TabPanel value={tabValue} index={0}>
                    <Grid item xs={12}>
                        {searchToolbar}
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="table"
                                id="exportTable"
                            >
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>{t('reports.walletBalance.walletName')}</TableCell> */}
                                        <TableCell>{t('reports.walletBalance.shopName')}</TableCell>
                                        <TableCell>{t('reports.walletBalance.email')}</TableCell>
                                        {/* <TableCell>{t('reports.walletBalance.amount')}</TableCell> */}
                                        { _.map(wallets, wallet => {
                                            return <TableCell key={wallet.id}>{ wallet.wallet_name }</TableCell>
                                        }) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listing.map((row) => {
                                        // let name = _.split(row.wallet_name, '|');
                                        return(
                                            <TableRow key={row.id}>
                                                {/* <TableCell component="th" scope="row">{ i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}</TableCell> */}
                                                <TableCell>{ row.shop_name }<span style={{ color: 'blue' }}>{ ` [${row.company_name}]` }</span></TableCell>
                                                <TableCell>{ row.email }</TableCell>
                                                { _.map(wallets, wallet => {
                                                    let balance = 0;
                                                    const exist = _.find(row.wallet_balance, { wallet_id: wallet.id });
                                                    if(exist) {
                                                        balance = exist.balance;
                                                    }
                                                    return <TableCell key={wallet.id}><NumberFormat value={balance} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                                }) }
                                                {/* <TableCell><NumberFormat value={row.balance} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell> */}
                                            </TableRow>
                                        );
                                    })}
                                    {listing.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={10} align="center">
                                                { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalCount > 0 ?
                            <TablePagination
                                rowsPerPageOptions={totalCount ? [...rowsPerPageOptiosArray, { value: totalCount, label: 'All' }] : rowsPerPageOptiosArray}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page-1}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage={t('general.rowsPerPage')}
                            />
                            : null
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid item xs={12}>
                            {searchToolbar}
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="table"
                                    id="exportTable"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>{t('reports.walletBalance.walletName')}</TableCell> */}
                                            <TableCell>{t('reports.walletBalance.username')}</TableCell>
                                            <TableCell>{t('reports.walletBalance.email')}</TableCell>
                                            <TableCell>{t('reports.walletBalance.mobile')}</TableCell>
                                            {/* <TableCell>{t('reports.walletBalance.amount')}</TableCell> */}
                                            { _.map(wallets, wallet => {
                                                return <TableCell key={wallet.id}>{ wallet.wallet_name }</TableCell>
                                            }) }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listing.map((row) => {
                                            // let name = _.split(row.wallet_name, '|');
                                            return(
                                                <TableRow key={row.id}>
                                                    {/* <TableCell component="th" scope="row">{ i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}</TableCell> */}
                                                    <TableCell>{ row.username }</TableCell>
                                                    <TableCell>{ row.email }</TableCell>
                                                    <TableCell>{ row.mobile }</TableCell>
                                                    { _.map(wallets, wallet => {
                                                        let balance = 0;
                                                        const exist = _.find(row.wallet_balance, { wallet_id: wallet.id });
                                                        if(exist) {
                                                            balance = exist.balance;
                                                        }
                                                        return <TableCell key={wallet.id}><NumberFormat value={balance} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                                    }) }
                                                    {/* <TableCell><NumberFormat value={row.balance} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                        {listing.length === 0 && (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={10} align="center">
                                                    { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {totalCount > 0 ?
                                <TablePagination
                                    rowsPerPageOptions={totalCount ? [...rowsPerPageOptiosArray, { value: totalCount, label: 'All' }] : rowsPerPageOptiosArray}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page-1}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    labelRowsPerPage={t('general.rowsPerPage')}
                                />
                                : null
                            }
                    </Grid>
                </TabPanel>
            </Paper>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
}));