import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import _, { filter } from 'lodash';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery } from '@utils/Tools';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Grid, Paper, Hidden } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Input, InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, Button, IconButton, Icon, useTheme, Box, AppBar, Tab, Tabs, Select, MenuItem } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BarChartIcon from '@material-ui/icons/BarChart';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import RestoreIcon from '@material-ui/icons/Restore';

import * as XLSX from 'xlsx';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box p={3}>
                <Typography component={'span'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export default function ReportWalletTransaction() {
    const classes = useStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const query = useQuery();
    const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];
    const pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const [page, setPage] = useState(pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [excel, setExcel] = useState([]);
    const [querySearch, setQueryValue] = useState(searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    // const [walletList, setWalletList] = useState([]);
    const { role } = useSelector(state => state.general);
    const [bonusList, setBonusList] = useState([]);
    const { permissions } = useSelector(state => state.general);
    const [tabValue, setTabValue] = useState(_.includes(permissions, "report_seller_wallet_history")?0:1);
    const [wallets, setWallets] = useState([]);
    const [transType, setTransType] = useState([]);
    const [filters, setFilter] = useState({'pay_code': "all", 'trans_type': "all"});

    const [searchTrigger, setSearchTrigger] = useState(1);
    const [dateFilter, setDateFilter] = useState({ sdate:'', edate:''});
    // const [reset, setReset]=useState(false);

    var reset = false;
    var countuser = 0;
    var countseller = 0;
    var currDate = (new Date().getFullYear()).toString() + '-' + (new Date().getMonth()+1).toString() + '-' + (new Date().getDate()).toString();
    var diffDate = new Date(dateFilter.edate ? dateFilter.edate : currDate).getMonth() - 
                   new Date(dateFilter.sdate ? dateFilter.sdate : currDate).getMonth() + 
                   12 * (new Date(dateFilter.edate ? dateFilter.edate : currDate).getFullYear() - 
                         new Date(dateFilter.sdate ? dateFilter.sdate : currDate).getFullYear());
    
    var initDate = new Date();
    // initDate.setMonth(initDate.getMonth());
    // initDate = initDate.getFullYear().toString() + '-' + initDate.getMonth().toString().padStart(2, '0') + '-' + initDate.getDate().toString().padStart(2, '0');

    function handleSubmit(event) {
        event.preventDefault();        
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue);
    };
    const handleTabChange = (event, newValue) => {
        setPage(1);
        setRowsPerPage(10);
        setTabValue(newValue);
    };

    const setUrl = (paging, rpp, sv) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/wallet-transaction${searchString !== '' ? '?'+searchString : ''}`);
    }
    /*
    const handleChangeFilters = (key, value) => {
        setFilter({...filters, [key]: value});
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    };
    */

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`bonus_list`).then(response => {
            if(isMountedRef.current){
                if(response.status === 1){
                    setBonusList(response.data);
                }else{
                    let msg = response.data;
                    addAlert(msg, 'error');
                }
            }
        }).catch((error) => {
            let msg = error+"\n"+t('error.contactSupport');
            addAlert(msg, 'error');
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        callApi();

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, addAlert, querySearch, tabValue]);

    const handleChangeFilters = event => {
        const { name, value } = event.target;
        setFilter({ ...filters, [name]: value });
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    }

    const handleDateChange = event => {
        const { name, value } = event.target;
        setDateFilter({ ...dateFilter, [name]: value });
        setPage(1);
        setUrl(1, rowsPerPage, searchValue);
    }

    const handleReset = () => {
        reset = true;
        callApi();
    }
    
    const handleFilter = () => {
        callApi();
    }

    const callApi = () => {
        let type = '';
        if(tabValue === 0){
            type = 'user';
        }else if(tabValue === 1){
            type = 'company';
        }

        let newEndDate = new Date(dateFilter.edate ? dateFilter.edate : currDate).setDate(new Date(dateFilter.edate ? dateFilter.edate : currDate).getDate() + 1);
        let params = {};
        if (reset) {
            params = {
                'page': 1, 
                'per_page':  rowsPerPage, 
                'q': searchValue, 
                'type': type, 
                'pay_code': "",
                'trans_type': "",
            };
            setDateFilter({...dateFilter, sdate: initDate, edate: initDate});
            setFilter({ ...filters, 'pay_code': "all", 'trans_type': "all" });
            setPage(1);
            setUrl(1, rowsPerPage, searchValue);
        } else {
            params = {
                'page': page, 
                'per_page':  rowsPerPage, 
                'q': querySearch, 
                'type': type, 
                'pay_code': filters.pay_code == "all" ? "" : filters.pay_code, 
                'trans_type': filters.trans_type == "all" ? "" : filters.trans_type,
            };
        }
        setTableLoading(true);

        // getUrl('admin/wallet_balance/company').then(result => {
        //     if(isMountedRef.current && result.status===1) {
        //         setWalletList(result.data);
        //     }
        // }).catch((error) => {
        //     addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        // });

        getUrl('admin/reports/wallet_transaction', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                reset = false;
                if(result.status === 1){
                    setExcel(result.excel);
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                    setWallets(result.wallets);
                    setTransType(result.trans_type);
                    if(rowsPerPageOptiosArray.indexOf(parseInt(rowsPerPage)) == -1){
                        setRowsPerPage(result.data.total);
                        setUrl(1, parseInt(result.data.total, 10), searchValue);
                    }
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                reset = false;
                setTableLoading(false);
                setListing([]);
                setExcel([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    function TableToolbar(props) {
        const classes = useStyles();
        const { t } = useTranslation();
        const [value, setValue] = useState(props.val);
        const searchInputRef = useRef(null);
        const handleClear = () => {
            setValue("");
            searchInputRef.current.focus();
        };
    
        const exportFile = () => {
            //let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
            //XLSX.writeFile(wb, `ReportWalletTransaction${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
    
            const newListingData = excel.map(row => {
                if (row.ref_type == 'user') {
                    let name = '';
                    name = _.split(wallets[row.pay_code], '|');
    
                    let name2 = '';
                    name2 = transType[row.type];
    
                    return {
                        "Transaction Type" : name2[i18n.language],
                        "Username" : row.username, 
                        "Email" : row.email, 
                        "Mobile" : row.mobile, 
                        "Wallet Type" : i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0],
                        "Amount" : (row.factor * row.amount).toFixed(2),
                        // "Balance" : row.balance,
                        "Remark" : row.remark ? row.remark : '-', 
                        "Created_At" : row.created_at,
                    };
                } else {
                    let name = '';
                    name = _.split(wallets[row.pay_code], '|');
    
                    let name2 = '';
                    name2 = transType[row.type];
    
                    return {
                        "Transaction Type" : name2[i18n.language],
                        "Shop Name" : row.shop_name, 
                        "Wallet Type" : i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0],
                        "Amount" : (row.factor * row.amount).toFixed(2),
                        // "Balance" : row.balance,
                        "Remark" : row.remark ? row.remark : '-', 
                        "Created_At" : row.created_at,
                    };
                }
            });
    
            var worksheet = XLSX.utils.json_to_sheet(
                newListingData,
                tabValue == 0
                ?   {header: ["Transaction Type", "Username", "Email", "Mobile", "Wallet Type", "Amount", "Remark", "Created_At"]}
                :   {header: ["Transaction Type", "Shop Name", "Wallet Type", "Amount", "Remark", "Created_At"]}
            );
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, tabValue == 0 ? 'WTHistory - Users' : 'WTHistory - Sellers');
    
            XLSX.writeFile(workbook, `ReportWalletTransaction${value ? `_${value.substr(0, 7)}` : ""}_${tabValue == 0 ? 'Users' : 'Sellers'}_${Date.now()}.xlsx`);
        }
    
        const toolbarSearch =
            <Toolbar className={classes.toolbarRoot}>
                 <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAltIcon fontSize="small" /></IconButton></Tooltip>
                <form noValidate onSubmit={props.handleSubmit}>
                    <FormControl>
                        <Input
                            id="search-input"
                            placeholder={t('table.search')}
                            startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                            endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearIcon fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                            aria-describedby="search"
                            inputProps={{
                                'aria-label': 'search',
                            }}
                            inputRef={searchInputRef}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </FormControl>
                </form>
            </Toolbar>;
            
        return [value, toolbarSearch];
    }
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.walletTransaction.title')}</Typography>
                    {/* <Tooltip title={t('title.reports')}><Link underline='none' to="/reports" component={RouterLink}><IconButton color="primary"><BarChartIcon /></IconButton></Link></Tooltip> */}
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                <Grid item>
                    <TextField
                        id="datetime-local"
                        label={t('reports.dailysales.from')}
                        type="date"
                        value={dateFilter.sdate ? dateFilter.sdate : initDate}
                        name="sdate"
                        onChange={handleDateChange}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ max: currDate }}
                        style={{ padding: 5 }}
                    />
                    <TextField
                        id="datetime-local"
                        label={t('reports.dailysales.to')}
                        type="date"
                        value={dateFilter.edate ? dateFilter.edate : initDate}
                        name="edate"
                        onChange={handleDateChange}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ max: currDate }}
                        style={{ padding: 5 }}
                    />
                    <TextField
                        id="select-pay_code"
                        label={t('reports.walletTransaction.allWallets')}
                        select
                        value={filters.pay_code}
                        name="pay_code"
                        onChange={handleChangeFilters}
                        InputLabelProps={{ shrink: true }}
                        style={{ padding: 5 }}
                    >
                        <MenuItem value="all">{t('reports.walletTransaction.allWallets')}</MenuItem>
                        {
                            _.map(wallets, (option, id) => {
                                let name = _.split(option, '|');
                                name =  i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                return <MenuItem key={id} value={id}>{`${name}`}</MenuItem>
                            })
                        }
                    </TextField>
                    <TextField
                        id="select-trans_type"
                        label={t('reports.walletTransaction.allTransType')}
                        select
                        value={filters.trans_type}
                        name="trans_type"
                        onChange={handleChangeFilters}
                        InputLabelProps={{ shrink: true }}
                        style={{ padding: 5 }}
                    >
                        <MenuItem value="all">{t('reports.walletTransaction.allTransType')}</MenuItem>
                        {
                            _.map(transType, (option, id) => {
                                return <MenuItem key={id} value={id}>{`${option[i18n.language]}`}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>

                <Grid item>
                    <Button size="medium" variant="outlined" color="inherit" onClick={() => handleReset()} startIcon={<RestoreIcon />}>{t('button.reset')}</Button>
                    <Button size="medium" variant="contained" color="primary" onClick={() => handleFilter()} startIcon={<FilterListIcon />} style={{marginLeft:10}}>{t('button.filter')}</Button>
                </Grid>
            </Grid>

            <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:theme.palette.primary.main ,WebkitBoxShadow:'none'}} >
                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" scrollButtons="on" >
                    {
                        _.includes(permissions, "report_member_wallet_history") ?
                            (<Tab icon={<PersonIcon />} label={t('reports.walletTransaction.user')} {...a11yProps(0)} value={0} />)
                            : ("")
                    }
                    {
                        _.includes(permissions, "report_seller_wallet_history") ?
                            (<Tab icon={<StorefrontIcon />} label={t('reports.walletTransaction.company')} {...a11yProps(1)} value={1} />)
                            : ("")
                    }
                </Tabs>
            </AppBar>

            <Paper className={classes.paper} style={{ ...theme.box1, ...theme.mt30 }}>
                <TabPanel value={tabValue} index={0}>
                    {searchToolbar}
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('reports.walletTransaction.type')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.userInfo')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.amount')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.details')}</TableCell>
                                        {/* <TableCell>{t('reports.walletTransaction.balance')}</TableCell> */}
                                        <TableCell>{t('reports.walletTransaction.remark')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.createdAt')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listing.map((row) => {
                                        countuser++;
                                        return (
                                            <TableRow key={countuser}>
                                                <TableCell component="th" scope="row">
                                                    {
                                                        row.type === 'bonus-distributed'
                                                            ?
                                                            <>
                                                                {
                                                                    _.map(bonusList, bonus => {
                                                                        if (bonus.id === row.type_id) {
                                                                            let name = _.split(bonus.name, '|');
                                                                            return (
                                                                                <Box key={bonus.id}>
                                                                                    {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0])}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <Box>{row.type_display[i18n.language]}</Box>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Box>{t('reports.walletTransaction.username') + ": " + (row.username ? row.username : '-')}<br/></Box>
                                                    <Box>{t('reports.walletTransaction.email') + ": " + (row.email ? row.email : '-')}<br/></Box>
                                                    <Box>{t('reports.walletTransaction.mobile') + ": " + (row.mobile ? row.mobile : '-')}<br/></Box>
                                                </TableCell>
                                                <TableCell><NumberFormat value={row.factor * row.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} style={{ color: (row.factor > 0 ? "green" : "red") }} /></TableCell>
                                                <TableCell>
                                                    {
                                                        _.map(row.transaction_details, (details, key) => {
                                                            let name = '';
                                                            let prefix = '';
                                                            if (details.wallet_name) {
                                                                name = _.split(details.wallet_name, '|');
                                                                prefix = (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]);
                                                            } else {
                                                                prefix = details.pay_code;
                                                            }

                                                            return (
                                                                <Box key={key}>
                                                                    {prefix + ': '}
                                                                    <NumberFormat value={details.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        _.map(row.pay_code, (details, key) => {
                                                            let name = '';
                                                            let prefix = '';

                                                            if (row.pay_code == details) {
                                                                name = _.split(wallets[row.pay_code], '|');
                                                                prefix = (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]);
                                                            } else {
                                                                prefix = row.pay_code;
                                                            }
                                                            return (
                                                                <Box key={key}>
                                                                    {prefix + ': '}
                                                                    <NumberFormat value={row.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </TableCell>
                                                {/* <TableCell>
                                                {
                                                    _.map(row.transaction_details, (details, key) => {
                                                        let name = _.split(details.wallet_name, '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={details.new_balance} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                } */}
                                                {/* {
                                                    _.map(row.pay_code, (details, key) => {
                                                        let name = _.split(wallets[row.pay_code], '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={details.new_balance} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                } */}
                                                {/* </TableCell> */}
                                                <TableCell>{row.remark ? row.remark : '-'}</TableCell>
                                                <TableCell>{row.created_at}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {listing.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={7} align="center">
                                                {tableLoading ? <LinearProgress /> : t('table.noRecord')}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalCount > 0 ?
                            <TablePagination
                                rowsPerPageOptions={[...rowsPerPageOptiosArray, { value: totalCount, label: 'All' }]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page-1}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage={t('general.rowsPerPage')}
                            />
                            : null
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {searchToolbar}
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('reports.walletTransaction.type')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.shopName')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.amount')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.details')}</TableCell>
                                        {/* <TableCell>{t('reports.walletTransaction.balance')}</TableCell> */}
                                        <TableCell>{t('reports.walletTransaction.remark')}</TableCell>
                                        <TableCell>{t('reports.walletTransaction.createdAt')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listing.map((row) => {
                                        countseller++;
                                        return(
                                            <TableRow key={countseller}>
                                                <TableCell component="th" scope="row">{row.type_display[i18n.language]}</TableCell>
                                                <TableCell>{ row.shop_name }</TableCell>
                                                <TableCell><NumberFormat value={row.factor*row.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} style={{color: (row.factor>0?"green":"red")}} /></TableCell>
                                                <TableCell>
                                                {
                                                    _.map(row.transaction_details, (details, key) => {
                                                        let name = _.split(details.wallet_name, '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={details.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                }
                                                {

                                                    _.map(row.pay_code, (details, key) => {
                                                        let name = _.split(wallets[row.pay_code], '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={row.amount} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                }
                                                </TableCell>
                                                {/* <TableCell>
                                                {
                                                    _.map(row.transaction_details, (details, key) => {
                                                        let name = _.split(details.wallet_name, '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={details.new_balance} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                } */}
                                                {/* {
                                                    _.map(row.pay_code, (details, key) => {
                                                        let name = _.split(wallets[row.pay_code], '|');
                                                        return (
                                                            <Box key={key}>
                                                                { (i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' }
                                                                <NumberFormat value={details.new_balance} decimalScale={2} fixedDecimalScale={2} displayType={'text'} thousandSeparator={true} />
                                                            </Box>
                                                        )
                                                    })
                                                } */}
                                                {/* </TableCell> */}
                                                <TableCell>{ row.remark?row.remark:'-' }</TableCell>
                                                <TableCell>{ row.created_at }</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {listing.length === 0 && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={7} align="center">
                                                { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            totalCount > 0 ?
                            <TablePagination
                                rowsPerPageOptions={[...rowsPerPageOptiosArray, { value: totalCount, label: 'All' }]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page-1}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage={t('general.rowsPerPage')}
                            />
                            : null
                        }
                    </Grid>
                </TabPanel>
            </Paper>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    excelDisplay: {
        display: 'none',
    }
}));