import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { postUrl } from '@utils/ApiAction';
import { APP_NAME } from '@configs/Config';
import useNotificationLoading from '@utils/useNotificationLoading';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Link, Paper, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function SellerTnc() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [email, setEmail] = useState('');

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container component="main" className={classes.root} justify="center">
            <Grid item xs={12} sm={8} md={8} component={Paper} square style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.paper} style={{ flex: 1 }}>
                    <Link color="inherit" href="/login">
                        <img src="/images/logo/logo-fit.png" style={{ width: 160, maxWidth:'100%' }} alt="logo" className={classes.logoImg} />
                    </Link>
                    <Typography component="h1" variant="h5" style={{ marginTop: '20px' }}>
                        Terms and Conditions
                    </Typography>
                    <Divider variant="middle" style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }} />
                    <Typography variant="body2" style={{ flexGrow: 1, width: "100%" }}>
                        1. We have the final decision on everything.<br />
                        2. We have the rights to do anything.<br />
                        3. You have no power here.<br />
                    </Typography>
                </div>
                <Copyright />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));