import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { getUrl, putUrl } from '@utils/ApiAction';
import { Editor } from '@tinymce/tinymce-react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, TextField, Button, Divider, Grid, FormControl, FormHelperText, GridList, GridListTile, GridListTileBar, Modal, MenuItem, Radio, RadioGroup, FormControlLabel, Checkbox, Slider, Switch  } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';

import { DropzoneArea } from 'material-ui-dropzone';

import CategoryPanel from '@pages/Category/CategoryPanel';
import ProductColorPicker from '@components/ProductColorPicker';

function useInput({ type, label, val, placeholder, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={6}>
            <TextField fullWidth size="small" label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} placeholder={placeholder} />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, input, setErrorValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val ? true : false);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

function useCompanySelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {`${option.shop_name} [ID: ${option.id}]`}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useRefundSelect({ data, label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useWalletSelect({ data, label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid item xs={12} sm={6}>
            <TextField
                select
                size="small"
                label={label}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                variant="outlined"
                fullWidth
                error={error ? true : false}
                helperText={error}
            >
                {data.map((option) => {
                    let name = _.split(option.name, '|');
                    return(
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <Typography variant="body2" style={{color: "#808080"}}>{ note }</Typography>
        </Grid>;
    return [value, select, setErrorValue, setValue];
}

function useEditor({ label, val }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const input =
        <Grid item xs={12} sm={12}>
            <FormControl variant="outlined" fullWidth size="small" error={error ? true : false}>
                <Typography variant="subtitle2" component="label">{label}</Typography>
                <Editor
                    apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                    value={value}
                    init={{
                        height: "70vh",
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount quickbars'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                    }}
                    onEditorChange={(content, editor) => setValue(content)}
                />
                {error ?
                    <FormHelperText>{error}</FormHelperText>
                    : null
                }
            </FormControl>
        </Grid>;
    return [value, input, setErrorValue, error];
}

export default function ProductEditGeneral({ data, companies, setLoading, addAlert, setData, pointList, pointData, setPointData, categoryProducts, mranks, ranks, isMountedRef, claimInfo }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { role, permissions, isAdmin } = useSelector(state => state.general);
    // color picker
    const [backgroundColor, setBackgroundColor] = useState(data.display_code.background_color);
    const [textColor, setTextColor] = useState(data.display_code.text_color);
    const [showPreview, setShowPreview] = useState(!data.display_code.default);
    const [subAccountBonus, setSubAccountBonus] = useState(data.sub_account_bonus ?? false);
    const [dropzoneKey, setDropzoneKey] = React.useState(0);

    const handleTogglePreview = () => {
        setShowPreview(!showPreview);
    };

    const handleToggleSubAccountBonus = () => {
        setSubAccountBonus(!subAccountBonus);
    };

    const [orderClaimDisplay, setOrderClaimDisplay] = useState("");

    const productType = isAdmin? [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}/** , {code: "repurchase", label: t('product.type.repurchase')}*/ ] : [{code: "normal", label: t('product.type.normal')}, {code: "starter", label: t('product.type.starter')}];
    const [type, productTypeInput, setProductTypeError] = useRefundSelect({ data: productType, label: `${t('product.type.title')}*`, val: data.type });
    const [title, titleInput, setNameError] = useInput({ type: "text", label: `${t('product.productName')}*`, val: data.title, placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    const [sku, skuInput, setSkuError] = useInput({ type: "text", label: `${t('product.sku')}`, val: data.sku || "" });
    const [goods_brand, brandInput, setBrandError] = useInput({ type: "text", label: t('product.brand'), val: data.goods_brand || "", placeholder:`${t('general.inputTips')}: ${t('product.english')}|${t('product.mandarin')}` });
    // const [sell_price, sellingPriceInput, setSellingPriceError] = useInput({ type: "number", label: t('product.price'), val: data.sell_price });
    const [cost_price, costPriceInput, setCostPriceError] = useInput({ type: "number", label: t('product.costPrice'), val: data.cost_price, note: t('product.claimableIncome') + ': ' + orderClaimDisplay });
    const [retail_price, retailPriceInput, setRetailPriceError] = useInput({ type: "number", label: t('product.retailPrice'), val: data.retail_price });
    const [merchant_company_id, companySelect, setCompanyError] = useCompanySelect({ data: companies, label: `${t('product.shopName')}*`, val: data.merchant_company_id });
    const [descr_en, descrEnInput, setDescrEnError, descrEnError] = useEditor({ label: t('product.descEn'), val: data.descr_en });
    const [descr_cn, descrCnInput, setDescrCnError, descrCnError] = useEditor({ label: t('product.descCn'), val: data.descr_cn });
    const [quantity, quantityInput, setQuantityError] = useInput({ type: "number", label: `${t('product.stock')}*`, val: data.quantity });
    const [shipping_fee, shippingFeeInput, setShippingFeeError] = useInput({ type: "number", label: t('product.shippingFee'), val: data.shipping_fee });
    const [west_shipping_fee, westShippingFeeInput, setWestShippingFeeError] = useInput({  type: "number", label: t('product.westShippingFee'), val: data.shipping_fee });
    const [east_shipping_fee, eastShippingFeeInput, setEastShippingFeeError] = useInput({  type: "number", label: t('product.eastShippingFee'), val: data.shipping_fee2 });
    const [ship_weight, weightInput, setWeightError] = useInput({ type: "number", label: `${t('product.weightKg')}*`, val: data.ship_weight || "" });
    const [package_length, lengthInput, setLengthError] = useInput({ type: "number", label: t('product.lengthCm'), val: data.package_length || "" });
    const [package_width, widthInput, setWidthError] = useInput({ type: "number", label: t('product.widthCm'), val: data.package_width || ""  });
    const [package_height, heightInput, setHeightError] = useInput({ type: "number", label: t('product.heightCm'), val: data.package_height || ""  });
    const [prepare_time, prepareTimeInput, setPrepareTimeError] = useInput({ type: "number", label: `${ t('product.prepareTime')}*`, val: data.prepare_time || "", note: t('product.prepareTimeNote') });
    const [purchase_limit, purchaseLimitInput, setPurchaseLimitError] = useInput({ type: "number", label: `${ t('product.purchaseLimit')}*`, val: data.purchase_limit, note: t('product.purchaseLimitNote') });
    const [order_limit, orderLimitInput, setOrderLimitError] = useInput({ type: "number", label: `${ t('product.orderLimit')}*`, val: data.order_limit, note: t('product.orderLimitNote') });
    // const [bv, bvInput, setBvError] = useInput({ type: "number", label: t('product.bv'), val: data.bv || "" });
    const [maxCap, maxCapInput, setMaxCapError] = useInput({ type: "number", label: t('product.maxCap'), val: data.max_cap});
    const [rank_id, rankIdInput, setRankIdError] = useWalletSelect({ data: ranks, label: `${t('product.relatedRank')}*`, val: data.rank_id?data.rank_id:0, note: t('product.relatedRankNote') });
    const [mrank_id, mrankIdInput, setMRankIdError] = useWalletSelect({ data: mranks, label: `${t('product.leadershipRank')}*`, val: data.mrank_id?data.mrank_id:0, note: t('product.leadershipRankNote') });
    const [seller_cover_shipping, sellerCoverShippingInput, setSellerCoverShippingInputError] = useCheckbox({ label: `${ t('product.sellerCoverShipping')}`, val: data.seller_cover_shipping ? true : false});
    const [sponsor_percent, sponsorPercentInput, setSponsorPercentError] = useInput({ type: "number", label: `${t('product.sponsorPercent')}*`, val: data.sponsor_percent });
    // const [sell_price_repurchase, sellingPriceReInput, setSellingPriceReError] = useInput({ type: "number", label: t('product.priceRepurchase'), val: data.price_repurchase?data.price_repurchase:'' });
    // const [bv_repurchase, bvReInput, setBvReError] = useInput({ type: "number", label: t('product.bvRepurchase'), val: data.bv_repurchase?data.bv_repurchase:'' });
    
    const [imagesArray, setImagesArray] = useState(data.images_array);
    const [newImages, setNewImages] = useState([]);
    const [newImagesError, setNewImagesError] = useState("");
    const [defaultCategoryId, setDefaultCategoryId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [newVideo, setNewVideo] = useState(null);
    const [newEmbedVideo, setNewEmbedVideo] = useState(data.embed_video ? data.embed_video : "");
    const [newVideoError, setNewVideoError] = useState("");

    const [shipping_channel, setShippingChannel] = useState(data.shipping_channel);
    const [malaysiaMerchant, setMalaysiaMerchant] = useState(false); // if true, can set shipping for east and west msia
    const [directPayment, setDirectPayment] = useState( data.direct_payment ?? false );
    const [directPaymentError, setDirectPaymentError] = useState("");

    const [minGroupPercent, setMinGroupPercent] = useState(0);
    const [groupPercent, setGroupPercent] = useState(data.group_percent);
    const [groupPercentError, setGroupPercentError] = useState("");

    const [bv, setBv] = useState(data.bv);
    const [bvError, setBvError] = useState("");
    const [bvRatio, setBvRatio] = useState(0);

    const [sellPrice, setSellPrice] = useState(data.sell_price);
    const [sellPriceError, setSellPriceError] = useState("");
    
    const validate = { title: setNameError, sku: setSkuError, goods_brand: setBrandError, sell_price: setSellPriceError, cost_price: setCostPriceError,
        retail_price: setRetailPriceError, category_id: setCategoryError, descr_en: setDescrEnError, descr_cn: setDescrCnError, quantity: setQuantityError,
        new_images: setNewImagesError, merchant_company_id: setCompanyError, shipping_fee: setShippingFeeError, shipping_fee2: setEastShippingFeeError, ship_weight: setWeightError, package_length: setLengthError,
        package_width: setWidthError, package_height: setHeightError, prepare_time: setPrepareTimeError, purchase_limit: setPurchaseLimitError,order_limit:setOrderLimitError, new_video: setNewVideoError, embed_video: setNewVideoError,
        bv: setBvError, rank_id: setRankIdError,mrank_id: setMRankIdError,
        seller_cover_shipping: setSellerCoverShippingInputError, type: setProductTypeError,
        sponsor_percent: setSponsorPercentError,group_percent: setGroupPercentError,
        // sell_price_repurchase: setSellingPriceReError, bv_repurchase: setBvReError,
        // sub_account_bonus: setSubAccountBonusError,
        maxCap:setMaxCapError
    };

    let deleteImage = (item) => {
        setImagesArray(_.difference(imagesArray, [item]));
    }
    const [openModal, setOpenModal] = useState("");

    const resetAllFieldError = () => {
        _.map(validate, (valid) => {
            valid("");
        })
    };

    useEffect(() => {
        let tempPointData = [];
        _.map((data.points), (data, key) => {
            let visible = false;
            _.map((pointList), (point) => {
                if(parseInt(point.id) === data.point_id){
                    visible = true;
                }
            });
            if(visible){
                tempPointData[key] = {id: data.id, point_id: data.point_id, point_source: data.point_source, point_name: data.point_name, value: data.value, deleted: false}
            }
        });
        let filteredArray = tempPointData.filter(item => item !== null);
        setPointData(filteredArray);
        setImagesArray(data.images_array);
        setCategoryId(data.category_id);
        setDefaultCategoryId(data.category_id);
    }, [data, pointList, setPointData]);
    
    // grab merchant company country for checking
    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`admin/companies/${merchant_company_id}`).then(response => {
            if(response.status && isMountedRef.current) {
                if(response.data.country === 'MY') {
                    setMalaysiaMerchant(true);
                } else {
                    setMalaysiaMerchant(false);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
    }, [merchant_company_id]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`admin/categories`).then(result => {
            setLoading(false);
            if(result.status === 1 && isMountedRef.current) {
                setMinGroupPercent(result.min_precent);
                // setGroupPercent(result.min_precent);
                setBvRatio(result.ratio);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })
        return () => isMountedRef.current = false;
    }, []);

    useEffect(() => {
        let company = _.find(companies, function (c) { return parseInt(c.id) === parseInt(merchant_company_id); });
        let comm = company?.shop_commission > 0 ? company?.shop_commission : claimInfo.mallComm;
        let profit = type === 'starter' ? (sellPrice * 0.2) : (sellPrice - cost_price);
        let total = parseFloat((type === 'starter' ? profit : cost_price) - (profit * comm) - (profit * claimInfo.sst));
        let text = '';
        if (type === 'starter') {
            text = (total).toFixed(2) + ' SP';
        } else {
            _.map(claimInfo.walletDistribute, (wallet, percent) => {
                text += (text ? ', ' : '') + parseFloat(total * percent).toFixed(2) + ' ' + wallet.code;
            })
        }
        setOrderClaimDisplay(text);
        // eslint-disable-next-line
    }, [companies, merchant_company_id, claimInfo, type, sellPrice, cost_price]);

    const changeCategory = currentId => {
        setCategoryId(currentId);
    }

    const categoryTreeSelect = () => {
        return (
        <Grid item xs={12} sm={12}>
            <CategoryPanel initialData={categoryProducts} selectedId={defaultCategoryId} changeCategoryParent={changeCategory} />
            {
                categoryError
                ?
                <Typography variant="caption" style={{color: "red"}}>
                    {categoryError}
                </Typography>
                :null
            }
        </Grid>
        )
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    let handleSubmit = async () => {
        setLoading(true);
        resetAllFieldError();
        setDirectPaymentError();
        let newImagesBase64 = [];

        if(_.size(newImages) > 0){            
            for (const newImg of newImages) {
                newImagesBase64.push(await toBase64(newImg));
            }
        }

        let newVideoBase64 = "";
        if(_.size(newVideo) > 0){
            newVideoBase64 = await toBase64(newVideo[0]);
        }

        let apiData = {
            title,
            category_id: categoryId,
            merchant_company_id,
            goods_brand,
            sell_price:sellPrice,
            cost_price,
            retail_price,
            quantity,
            descr_en,
            descr_cn,
            images: imagesArray,
            new_images: newImagesBase64,
            shipping_channel,
            shipping_fee: malaysiaMerchant ? west_shipping_fee : shipping_fee,
            shipping_fee2: malaysiaMerchant ? east_shipping_fee : 0,
            ship_weight,
            package_length,
            package_width,
            package_height,
            prepare_time,
            purchase_limit,
            sku,
            new_video: newVideoBase64,
            embed_video: newEmbedVideo,
            bv,
            rank_id: type == 'normal' ? null : rank_id,
            mrank_id: type == 'normal' ? null : mrank_id,
            maxCap: type == 'normal' ? null : maxCap,
            seller_cover_shipping: seller_cover_shipping ? 1: 0,
            type,
            // sell_price_repurchase,
            // bv_repurchase,
            order_limit,
            showPreview,
            background_color: backgroundColor,
            text_color: textColor,
            subAccountBonus: subAccountBonus,
            directPayment: directPayment,
            sponsor_percent,
            group_percent: groupPercent,
        }
        putUrl(`admin/products/${data.id}`, apiData)
        .then((response) => {
            setLoading(false);
            let {status, message, error, data:newData} = response;
            if(status === 1){
                addAlert('', message, 'success', '');
                setData(newData);
                setNewEmbedVideo(newData.embed_video ? newData.embed_video : "");
                setNewImages([]);
                setDropzoneKey(prevKey => prevKey + 1);
            }else{                
                if(_.size(error) > 0){
                    _.map(error, (value, key) => {
                        if(validate[key])
                            validate[key](value[0]);
                        else
                            message += "\n "+value[0];
                    })
                    if (error?.directPayment)
                    {
                        setDirectPaymentError(error?.directPayment);
                    }
                    
                }
                addAlert('', message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        });
    }

    const handleOnDragEnd = (result) => {
        const { source, destination } = result;

        // Dropped outside the list
        if (!destination) return;

        const items = Array.from(imagesArray);
        const [movedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, movedItem);

        setImagesArray(items);
    };

    const handleChangeDirectPayment = () => {
        setDirectPayment(!directPayment);
    };

    const handlePercentChnage = (event) => {
        if(sellPrice > 0){
            setGroupPercent(event.target.value);
            let percent = event.target.value - minGroupPercent
            if(percent >= 0){
                setBv(Math.floor(((sellPrice * (percent/100)) / bvRatio) *100)/100)
            }
            
        }else{
            addAlert('', 'Please Fill in sell Price first', 'error', '');
        };
    };

    const handlePriceChange = (event) => {
        if(event.target.value > 0){
            setSellPrice(event.target.value);
            let percent = groupPercent - minGroupPercent
            if(percent >= 0){
                setBv(Math.floor(((event.target.value * (percent/100)) / bvRatio) *100)/100)
            }
            
        }else{
            addAlert('', 'Please Fill in sell Price first', 'error', '');
        };
    };

    return (
        <Grid container spacing={3} style={theme.p20}>
            <Grid item xs={12}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.aboutProduct')}</Typography>

            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.generalInfo')}</Typography>
                </Grid>
                {titleInput}
                {productTypeInput}
                {_.size(companies) ? companySelect : null}
                {skuInput}
                {brandInput}
                {categoryTreeSelect()}
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.priceAndBv')}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        size="small"
                        type="number"
                        variant="outlined"
                        value={sellPrice}
                        onChange={handlePriceChange}
                        error={sellPriceError ? true : false}
                        helperText={sellPriceError}
                        label={`${t('product.price')}*`}
                    />
                </Grid>
                {costPriceInput}
                {retailPriceInput}
                {_.includes(permissions, "product_bonus") ?
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            size="small"
                            type="number"
                            variant="outlined"
                            value={bv}
                            onChange={(e) => setBv(e.target.value)}
                            disabled={true}
                            error={bvError ? true : false}
                            helperText={bvError}
                            label={`${t('product.bv')}`}
                        />
                    </Grid> : null}
                {_.includes(permissions, "product_bonus") ? sponsorPercentInput : null}
                {_.includes(permissions, "product_bonus") ? 
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        variant="outlined"
                                        value={groupPercent}
                                        onChange={handlePercentChnage}
                                        // inputProps={{
                                        //     min: minGroupPercent, // Set minimum value
                                        // }}
                                        error={groupPercentError ? true : false} 
                                        helperText={groupPercentError?groupPercentError:t('product.groupPercentHelperText',{m:minGroupPercent})}
                                        label={`${t('product.groupPercent')}*`}
                                    />
                                </Grid> 
                : null}
                {
                    _.includes(permissions, "product_bonus") &&
                    <Grid item xs={12} sm={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 40, alignItems: 'center' }}>
                            <Typography>{t('product.subAccountBonus')}</Typography>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>{t('button.off')}</Grid>
                                    <Grid item>
                                        <Switch checked={subAccountBonus} onChange={handleToggleSubAccountBonus} />
                                    </Grid>
                                    <Grid item>{t('button.on')}</Grid>
                                </Grid>
                            </Typography>
                        </div>
                    </Grid>
                }
                            
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 40, alignItems: 'center' }}>
                        <Typography>{t('product.directPayment')}</Typography>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>{t('button.off')}</Grid>
                                <Grid item>
                                    <Switch checked={directPayment} onChange={handleChangeDirectPayment} />
                                </Grid>
                                <Grid item>{t('button.on')}</Grid>
                            </Grid>
                        </Typography>
                    </div>
                    {
                        directPaymentError ?
                            <FormHelperText style={{ color: "red" }}>{directPaymentError}</FormHelperText>
                            : null
                    }
                </Grid>

                {_.size(ranks) > 1 ? (type == 'normal' ? null : rankIdInput) : null}
                {_.size(mranks) > 1 ? (type == 'normal' ? null : mrankIdInput) : null}
                {type === 'normal'?null:maxCapInput}
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.stock')}</Typography>
                </Grid>
                {_.size(data.options[0]) === 0 ? quantityInput : null}
                {purchaseLimitInput}
                {orderLimitInput}
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.weightAndDimensional')}</Typography>
                </Grid>
                {weightInput}
                {lengthInput}
                {widthInput}
                {heightInput}
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.others')}</Typography>
                </Grid>
                {prepareTimeInput}
            </Grid>
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.productDescription')}</Typography>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_en")}>{t('product.english')}</Button>
                    {descrEnError ?
                        <FormHelperText style={{ color: "red" }}>{descrEnError}</FormHelperText>
                        : null
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" startIcon={<DescriptionIcon />} onClick={() => setOpenModal("descr_cn")}>{t('product.mandarin')}</Button>
                    {descrCnError ?
                        <FormHelperText style={{ color: "red" }}>{descrCnError}</FormHelperText>
                        : null
                    }
                </Grid>
            </Grid>
            <Modal
                open={openModal ? true : false}
                onClose={() => setOpenModal("")}
                aria-labelledby="product description"
                aria-describedby="product description modal"
            >
                <Grid container spacing={3} justify="center" className={classes.modal}>
                    { openModal === "descr_cn" ? descrCnInput : descrEnInput }
                    <Grid item xs={12} sm={6}>
                        <Button type="button" fullWidth variant="contained" color="primary" onClick={() => setOpenModal("")}>{t('button.close')}</Button>
                    </Grid>
                </Grid>
            </Modal>
            {data.shipping_channel === "source" ? null :
                <>
                    <Grid item xs={12} style={theme.mt30}>
                        <Typography variant="h5" style={theme.textline} component="h1">{t('product.shippingChannel')}</Typography>
                        {/* <Divider /> */}
                    </Grid>
                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="shipping channel" name="shippingChannel" value={shipping_channel} onChange={(event) => { setShippingChannel(event.target.value) }}>
                                    <FormControlLabel value="self" control={<Radio />} label={t('product.selfShipping')} />
                                    <FormControlLabel value="mall" control={<Radio />} label={t('product.mallShipping')} />
                                </RadioGroup>
                                <FormHelperText style={{paddingBottom: 5}}>{t('product.shippingWeightNote')}</FormHelperText>
                            </FormControl>
                            { shipping_channel === "self" ? 
                                <>
                                    { malaysiaMerchant ? 
                                        <>
                                            { westShippingFeeInput }
                                            <div style={{ marginTop: 10 }}>
                                                { eastShippingFeeInput }
                                            </div>
                                        </>
                                    : shippingFeeInput }
                                </>
                            : null }
                            {shipping_channel === "mall" ? sellerCoverShippingInput : null}
                            
                        </Grid>
                    </Grid>
                </>
            }
            <Grid item xs={12} style={theme.mt30}>
                <Typography variant="h5" style={theme.textline} component="h1">{t('product.images')}</Typography>
            </Grid>
            <div style={{ marginLeft: 15}}>
                <Typography variant="body2">{t('product.reminderImg')}</Typography>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-images" direction="horizontal">
                    {(provided) => (
                        <Grid
                            item
                            xs={12}
                            container
                            className={classes.imageRoot}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <GridList className={classes.gridList} cols={2.5} style={{ height: "unset" }}>
                                {imagesArray.map((item, index) => (
                                    <Draggable key={item} draggableId={item} index={index}>
                                        {(provided) => (
                                            <GridListTile
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    height: "unset",
                                                    width: "auto"
                                                }}
                                            >
                                            <div style={{ marginBottom: 40 }}>
                                                <img src={item} alt={`product ${index + 1}`} style={{ width: "250px", objectFit: "cover", aspectRatio: "1 / 1" }} />
                                            </div>
                                                <GridListTileBar
                                                    title={`Image ${index + 1}`}
                                                    classes={{
                                                        root: classes.titleBar,
                                                        title: classes.title,
                                                    }}
                                                    actionIcon={
                                                        <IconButton onClick={() => deleteImage(item)}>
                                                            <DeleteOutlineIcon className={classes.title} />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </GridList>
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadImages')}</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.imageRoot}>
                <DropzoneArea
                    key={dropzoneKey}
                    acceptedFiles={['image/*']}
                    dropzoneText={t('product.dragDropImageHere')}
                    onChange={(files) => isMountedRef.current ? setNewImages(files) : null}
                    filesLimit={10-_.size(imagesArray)}
                    dropzoneClass={classes.dropzone}
                    maxFileSize={2000000}
                />
                <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 10-_.size(imagesArray)}) + t('general.productfileSizeLimit', {limit: '2MB', fileType: 'jpeg. jpg. png. '})}</Typography>
                { newImagesError ?
                    <FormHelperText style={{color:"red"}}>{newImagesError}</FormHelperText>
                    : null
                }
            </Grid>
            {/* color picker */}
            <Grid item xs={12} sm={12}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>{t('product.subTitle.colorPicker')}</Typography>
            </Grid>
            {/* switch button */}
            <Grid item xs={12} sm={12}>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>{t('button.off')}</Grid>
                        <Grid item>
                            <Switch checked={showPreview} onChange={handleTogglePreview} />
                        </Grid>
                        <Grid item>{t('button.on')}</Grid>
                    </Grid>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ProductColorPicker showPreview={showPreview} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} textColor={textColor} setTextColor={setTextColor} newImages={newImages} imagesArray={imagesArray} />
            </Grid>
            <Grid item xs={12} style={{marginTop:"30px"}}>
                <Typography variant="h5"  style={theme.textline} component="h1">{t('product.uploadVideo')}</Typography>
            </Grid>
            <Grid item xs={12} container>
                {data.video ?
                    <Grid item xs={6}>
                        <video width="100%" controls style={{ paddingRight: 15 }}>
                            <source src={data.video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </Grid>
                    : null
                }
                {data.embed_video ?
                    <Grid item xs={6}>
                        <iframe width="100%" height="100%" src={data.embed_video} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                    </Grid>
                    : null
                }
                <Grid item xs={6}>
                    <DropzoneArea
                        acceptedFiles={['video/mp4']}
                        dropzoneText={t('product.dragDropVideoHere')}
                        onChange={(file) => setNewVideo(file)}
                        filesLimit={1}
                        dropzoneClass={classes.dropzone}
                        maxFileSize={5000000}
                    />
                    <Typography variant="body2" style={{margin: 5}}>{t('general.maxFile', {max: 1}) + t('general.fileSizeLimit', {limit: '5MB', fileType: 'MP4. '})}</Typography>
                    <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 5}}>
                        <Divider variant="middle" style={{ flex: 1}}/><Typography style={{ textTransform: "uppercase"}}>{t('product.or')}</Typography><Divider variant="middle" style={{ flex: 1}}/>
                    </Grid>
                    <TextField fullWidth size="small" label={t('product.embedVideoUrl')} variant="outlined" value={newEmbedVideo} onChange={e => setNewEmbedVideo(e.target.value)} type="text" placeholder={"e.g: https://www.youtube.com/embed/dQw4w9WgXcQ"} />
                    { newVideoError ?
                        <FormHelperText style={{color:"red"}}>{newVideoError}</FormHelperText>
                        : null
                    }
                </Grid>
            </Grid>
            <Grid container spacing={3} justify="flex-end">
                <Grid item xs={8} sm={6} md={4} style={{marginTop: 30}}>
                    <Button type="button" fullWidth variant="contained" color="primary" onClick={handleSubmit}>{t('button.update')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    imageRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        // transform: 'translateZ(0)',
        flexGrow: 1
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'transparent',
    },
    modal: {
        position: 'absolute',
        width: '95vw',
        height: '95vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: '20px',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        overflow: 'auto'
    },
    dropzone: {
        outline: "none",
    },

}));