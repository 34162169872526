import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, useTheme, Typography, Link, Grid, Paper, Input, 
    InputAdornment, FormControl, Tooltip, Toolbar, LinearProgress, 
    IconButton, Icon, Box, AppBar, Table, TableBody, TableCell, 
    TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, 
    Tab, Tabs
} from '@material-ui/core';
import { ClearOutlined, ReorderOutlined, ConfirmationNumberOutlined ,SaveAlt } from '@material-ui/icons';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useQuery } from '@utils/Tools';
import * as XLSX from 'xlsx';
import NumberFormat from 'react-number-format';

function TableToolbar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState(props.val);
    const searchInputRef = useRef(null);
    const handleClear = () => {
        setValue("");
        searchInputRef.current.focus();
    };
    const exportFile = () => {
        let wb = XLSX.utils.table_to_book(document.getElementById('exportTable'));
        XLSX.writeFile(wb, `ReportBonusDetails${value ? `_${value.substr(0, 7)}` : ""}_${Date.now()}.xlsx`);
    }

    const toolbarSearch =
        <Toolbar className={classes.toolbarRoot}>
            <Tooltip title={t('table.exportExcel')}><IconButton type="button" onClick={exportFile} className={classes.iconButton} aria-label="export-excel"><SaveAlt fontSize="small" /></IconButton></Tooltip>
            <form noValidate onSubmit={props.handleSubmit}>
                <FormControl>
                    <Input
                        id="search-input"
                        placeholder={t('table.search')}
                        startAdornment={<InputAdornment position="start"><Tooltip title={t('table.search')}><Icon fontSize="small" onClick={props.handleSubmit}>search</Icon></Tooltip></InputAdornment>}
                        endAdornment={<InputAdornment position="end"><Tooltip title={t('table.clear')}><IconButton type="button" onClick={handleClear} className={classes.iconButton} aria-label="clear-search"><ClearOutlined fontSize="small" /></IconButton></Tooltip></InputAdornment>}
                        aria-describedby="search"
                        inputProps={{
                            'aria-label': 'search',
                        }}
                        inputRef={searchInputRef}
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormControl>
            </form>
        </Toolbar>;
    return [value, toolbarSearch];
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, cellId, label, sortTable } = props;
    const { t } = useTranslation();
    return (
        <TableCell
            sortDirection={orderBy === cellId ? order : false}
        >
            <Tooltip title={t('table.sort')}>
                <TableSortLabel
                    active={orderBy === cellId}
                    direction={orderBy === cellId ? order : 'asc'}
                    onClick={() => sortTable(cellId)}
                >
                    {label}
                    {orderBy === cellId ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </Tooltip>
        </TableCell>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const rowsPerPageOptiosArray = [5, 10, 15, 20, 25];

export default function EventSummary() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const query = useQuery();

    const url_pageNumber = query.get("page") && query.get("page") > 0 ? parseInt(query.get("page")) :1;
    const url_perPage = query.get("per_page") && rowsPerPageOptiosArray.indexOf(parseInt(query.get("per_page"))) !== -1 ? parseInt(query.get("per_page")) : 10;
    const url_searchKeyword = query.get("search") && query.get("search") !== '' ? query.get("search") : '';
    const url_orderBy = query.get("orderBy") && query.get("orderBy") !== '' ? query.get("orderBy") : '';
    const url_order = query.get("order") && query.get("order") !== '' ? query.get("order") : 'asc';
    
    const [page, setPage] = useState(url_pageNumber);
    const [rowsPerPage, setRowsPerPage] = useState(url_perPage);
    const [totalCount, setTotalCount] = useState(0);
    const [listing, setListing] = useState([]);
    const [querySearch, setQueryValue] = useState(url_searchKeyword);
    const [tableLoading, setTableLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(url_orderBy);
    const [order, setOrder] = useState(url_order);
    const [tabValue, setTabValue] = useState(0);
    const [bonusList, setBonusList] = useState([]);

    const isMountedRef = useRef(null);


    useEffect(() => {
        isMountedRef.current = true;

        getUrl(`admin/bonuses?per_page=100&active_bonus=true`).then(response => {
            console.log("bonuses", response);
            if(response.status && isMountedRef.current) {
                setBonusList(response?.data?.data);
            }
        }).catch(error => {
            addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
        })

        return () => isMountedRef.current = false;
    }, [addAlert])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(1);
        setRowsPerPage(10);
        history.push('/reports/bonus-details')
    };

    function handleSubmit(event) {
        event.preventDefault();
        setPage(1);
        setUrl(1, rowsPerPage, searchValue, orderBy, order);
        setQueryValue(searchValue);
    }
    const [searchValue, searchToolbar] = TableToolbar({handleSubmit, val: url_searchKeyword});

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
        setUrl(newPage+1, '', searchValue, orderBy, order);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
        setUrl(1, parseInt(event.target.value, 10), searchValue, orderBy, order);
    };

    const sortTable = (column) => {
        setPage(1);
        setOrderBy(column);
        if(orderBy !== column){
            setOrder('asc');
        }else{
            setOrder(order === "asc" ? "desc" : "asc");
        }

        setUrl(1, '', searchValue, column, order === "asc" ? "desc" : "asc");
    };

    const setUrl = (paging, rpp, sv, column, orderSort) => {
        let params = {'page': paging, 'per_page':  rpp ? rpp : rowsPerPage };
        if(sv){
            params['search'] = sv;
        }
        if(column){
            params['orderBy'] = column;
            params['order'] = orderSort;
        }
        let searchString = new URLSearchParams(params).toString();
        history.push(`/reports/bonus-details${searchString !== '' ? '?'+searchString : ''}`);
    }

    function callApi(){
        let bonusType = '';
        console.log("tabValue", tabValue);
        // if(tabValue === 1){
        //     bonusType = 'sponsor-bonus';
        // }else if(tabValue === 2){
        //     bonusType = 'overriding-bonus';
        // }else if(tabValue === 3){
        //     bonusType = 'roi-bonus';
        // }
        const existBonus = _.find(bonusList, { id: tabValue });
        if(_.size(existBonus)) {
            bonusType = existBonus.bonus_type;
        }

        let params = {'page': page, 'per_page':  rowsPerPage, 'q': querySearch, 'order_by': orderBy, 'order': order, 'bonus_type': bonusType };
        setTableLoading(true);
        getUrl('admin/commissions/details', params).then(result => {
            if(isMountedRef.current) {
                setTableLoading(false);
                if(result.status === 1){
                    setListing(result.data.data);
                    setTotalCount(result.data.total);
                }
            }
        }).catch((error) => {
            if(isMountedRef.current) {
                setTableLoading(false);
                setListing([]);
                setTotalCount(0);
                addAlert('', error+"\n"+t('error.contactSupport'), 'error', '');
            }
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callApi();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [page, rowsPerPage, querySearch, orderBy, order, tabValue]);

    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justify="space-between" alignItems="flex-end">
                    <Typography variant="h4" component="h1">{t('reports.bonusDetails.title')}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" justify="space-around"  style={{backgroundColor:'transparent',color:theme.palette.primary.main,WebkitBoxShadow:'none'}}>
                    <Tabs value={tabValue} onChange={handleTabChange} centered indicatorColor="primary">
                        <Tab icon={<ReorderOutlined />} label={t('reports.bonusDetails.all')} {...a11yProps(0)} />
                        { _.map(bonusList, (bonusItem, bonusIndex) => (
                            <Tab key={bonusItem.id} icon={<ConfirmationNumberOutlined />} label={ bonusItem.name_display } value={bonusItem.id} />
                        ))}
                        {/* <Tab icon={<ConfirmationNumberOutlined />} label={t('reports.bonusDetails.sponsor')} {...a11yProps(1)} />
                        <Tab icon={<ConfirmationNumberOutlined />} label={t('reports.bonusDetails.overriding')} {...a11yProps(2)} />
                        <Tab icon={<ConfirmationNumberOutlined />} label={t('reports.bonusDetails.roi')} {...a11yProps(3)} /> */}
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} style={{ ...theme.box1, ...theme.p20 }}>
                    {searchToolbar}
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="table"
                            id="exportTable"
                        >
                            <TableHead>
                                <TableRow>
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.commId')} cellId={'id'} />
                                    {tabValue === 0 ? <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.bonusType')} cellId={'bonus_id'} /> : null}
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.user')} cellId={'user_id'} />
                                    {tabValue !== 3 ? <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.downline')} cellId={'downline_id'} /> : null}
                                    {tabValue === 3 ? <TableCell>{t('reports.bonusDetails.wallet')}</TableCell> : null}
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.bv')} cellId={'bv'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.percent')} cellId={'percent'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.amount')} cellId={'amount'} />
                                    {(tabValue !== 1 && tabValue !== 3) ? <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.level')} cellId={'level'} /> : null}
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.status')} cellId={'status'} />
                                    <EnhancedTableHead order={order} orderBy={orderBy} classes={classes} sortTable={sortTable} label={t('reports.bonusDetails.bonusDate')} cellId={'bonus_date'} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing.map((row) => {
                                    return(
                                        <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">{row.id}</TableCell>
                                                {
                                                    tabValue === 0 ?
                                                    <TableCell>
                                                        {row.bonus_info.bonus_type}
                                                        {
                                                            row.bonus_info.bonus_type === 'roi-bonus' && row.roi_wallet?
                                                            <Box>
                                                                { `(${row.roi_wallet})` }
                                                            </Box>
                                                            : null
                                                        }
                                                    </TableCell>
                                                    : null
                                                }
                                                <TableCell>
                                                    {
                                                        row.user_info?
                                                        <Link underline='none' to={`/members/${row.user_id}`} component={RouterLink}>{row.user_info.username?row.user_info.username:(row.user_info.email?row.user_info.email:(row.user_info.mobile?row.user_info.mobile:row.user_info.name))}</Link>
                                                        : null
                                                    }
                                                </TableCell>
                                                {
                                                    tabValue !== 3 && row.downline_info ?
                                                    <TableCell>
                                                        <Link underline='none' to={`/members/${row.downline_id}`} component={RouterLink}><Typography>{row.downline_info.username?row.downline_info.username:(row.downline_info.email?row.downline_info.email:(row.downline_info.mobile?row.downline_info.mobile:row.downline_info.name))}</Typography></Link>
                                                    </TableCell>
                                                    : null
                                                }
                                                {tabValue === 3 ? <TableCell>{row.roi_wallet}</TableCell> : null}
                                                <TableCell><NumberFormat value={row.bv} decimalScale={2} displayType={'text'} thousandSeparator={true} /></TableCell>
                                                <TableCell><NumberFormat value={row.percent} decimalScale={0} displayType={'text'} thousandSeparator={true} /></TableCell>
                                                <TableCell>
                                                    { _.isObject(row.wallets_amount) && row.wallets_amount ?
                                                        _.map(row.wallets_amount, (walletAmount, walletName) => {
                                                            let walletamount = parseFloat(walletAmount).toFixed(4);
                                                            return <p key={walletName}>{ `${walletamount} ${walletName}` }</p>
                                                        })
                                                        : row.amount
                                                    }
                                                </TableCell>
                                                { 
                                                    (tabValue !== 1 && tabValue !== 3) ? 
                                                    <TableCell>{row.level}</TableCell>
                                                    : null 
                                                }
                                                <TableCell>{t('reports.bonusDetails.commStatus.' + row.status)}</TableCell>
                                                <TableCell>{row.bonus_date}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {listing.length === 0 && (
                                    <TableRow style={{ height: 53 }}>
                                        <TableCell colSpan={9} align="center">
                                            { tableLoading ? <LinearProgress /> :t('table.noRecord') }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalCount > 0 ?
                        <TablePagination
                            rowsPerPageOptions={totalCount? [...rowsPerPageOptiosArray,{ value: totalCount, label: 'All' }]:rowsPerPageOptiosArray}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page-1}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('general.rowsPerPage')}
                        />
                        : null
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    iconButton: {
        padding: 10,
    },
    toolbarRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        flexDirection: 'row-reverse'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));